import React, {useEffect} from 'react';
import TwitterPipeline from "../../../LoadingAnimations/TwitterPipeline/TwitterPipeline";
import {useAPIConnector} from "../../../../hooks/useAPIConnector";
import {useSubsumptionResult} from "../../../../hooks/twitterDataHooks/useSubsumptionResult";
import {TwitterSteps, useProgressionHandling} from "../../Twitter";
import {useEvaluationResult} from "../../../../hooks/twitterDataHooks/useEvaluationResult";
import {useFactDescription} from "../../../../hooks/twitterDataHooks/useFactDescription";
import {useExpertMode} from "../../../../hooks/settingsCTXHooks/useExpertMode";

function QuerySwish() {
    const [factDescription] = useFactDescription()
    const {subsumptionResult} = useSubsumptionResult()
    const [expertMode] = useExpertMode()
    const [_, setEvaluationResult] = useEvaluationResult()
    const {setStep} = useProgressionHandling()
    const api = useAPIConnector()

    useEffect(() => {
        const overwrittenResponse = subsumptionResult.applyOverwrites()

        const abortController = new AbortController()
        api.querySwish({
            swishAPIRequestJSON: overwrittenResponse,
            expertMode: expertMode
        }, abortController.signal)
            .then((response) => {
                setEvaluationResult(response)
                handleGoToNextStep()
            })
            .catch(reason => {
                if (reason.code === "ERR_CANCELED") return
                throw reason
            })

        return () => abortController.abort()
    }, [])

    const handleGoToNextStep = () => {
        setStep(TwitterSteps.VIEW_RESULT)
    }

    return (
        <div>
            <p>
                Die Einschätzung von ChatGPT wird nun nach unseren eigens entwickelten Logik analysiert.
            </p>

            <div className={'SubsumeTweet-Visualization'}>
                <TwitterPipeline factDescription={factDescription} isLoading={true}
                                 curStep={'logic'}/>
            </div>
        </div>
    )
}

export default QuerySwish;