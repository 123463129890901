import "./Progress.scss"
import React, {CSSProperties, HTMLAttributes, ReactElement, useEffect, useState} from 'react';

export type ProgressOrientation = "horizontal" | "vertical"

export type DescriptionMode = "all" | "only-active" | "none"

export interface ProgressProps extends HTMLAttributes<HTMLDivElement> {
    numSteps: number,
    curStep: number,
    curProgress: number,
    orientation?: ProgressOrientation,
    onClickStep?: (step: number) => void
    stepNames?: Record<number, string | ReactElement>
    stepDescriptions?: Record<number, string | ReactElement>
    descriptionMode?: DescriptionMode
}

/**
 * This component visualizes a progression.
 * You can specify steps and the progress that was made in the current step.
 * The orientation controls whether it progresses horizontally (left to right) or vertically (top to bottom)
 *
 * @param numSteps the total number of steps
 * @param curStep the current step, i.e., the step that was lastly achieved
 * @param curProgress the progress of the current step in percent. E.g., if curProgress === 60, 60% of the progress between
 *      curStep and curStep + 1 will be colored.
 * @param orientation the orientation of the progress bar (default=horizontal):
 *      - horizontal: from left to right
 *      - vertical: from top to bottom
 * @param onClickStep callback function that is called when a specific step number is clicked.
 *      The number that was clicked is passed to the callback function.
 *      If a function is provided (!== undefined), the styling is updated to symbolize that the numbers are clickable
 * @param stepNames (optional) allows to overwrite the step number that is shown inside a single step.
 *      Map the step numbers to the content that should be rendered inside the respective step button.
 *      For missing step numbers in this property, the step number itself is rendered.
 * @param stepDescriptions (optional) allows the addition of a descriptive text underneath each step number.
 *      Map the step numbers to the respective description. If step numbers are missing, no description is rendered.
 * @param descriptionMode (optional) controls the visibility of the descriptions:
 *      - "all" (default): show all description
 *      - "only-active": show only the description of the currently active step
 *      - "none": hide all descriptions
 * @param props
 * @constructor
 */
function Progress({
                      numSteps,
                      curStep,
                      curProgress,
                      orientation,
                      onClickStep,
                      stepNames,
                      stepDescriptions,
                      descriptionMode,
                      ...props
                  }: ProgressProps) {
    const getStepName = (step: number): string | ReactElement => {
        if (stepNames === undefined) {
            // add 1 as the internal steps start at 0
            return `${step + 1}`
        }
        const nameOverwrite = stepNames[step]
        if (nameOverwrite === undefined) {
            // add 1 as the internal steps start at 0
            return `${step + 1}`
        }
        return nameOverwrite
    }

    const getStepDescription = (step: number): undefined | string | ReactElement => {
        if (stepDescriptions === undefined) {
            return undefined
        }
        const stepDescription = stepDescriptions[step]
        if (stepDescription === undefined) {
            return undefined
        }
        switch (descriptionMode) {
            case undefined: // default is "all"
            case "all":
                return stepDescription;
            case "only-active":
                if (step === curStep) {
                    return stepDescription
                }
                return undefined;
            case "none":
                return undefined
        }
        return undefined
    }

    return (
        <div {...props} className={`Progress ${orientation ?? 'horizontal'} ` + props.className ?? ''}>
            {
                // we use a dummy array of size numSteps to iterate over [0, numSteps) to generate the progression steps
                Array(numSteps).fill(0).map((_, step) => {
                    let state: ProgressionStepState = step < curStep ? 'completed' : 'active'
                    state = step > curStep ? 'future' : state
                    return <ProgressionStep step={step}
                                            state={state}
                                            progression={curProgress}
                                            hasProgression={step < numSteps - 1}
                                            orientation={orientation}
                                            key={step}
                                            onClickStep={onClickStep}
                                            stepNameOverwrite={getStepName(step)}
                                            description={getStepDescription(step)}
                    />
                })
            }
        </div>
    )
}

type ProgressionStepState = "completed" | "active" | "future"

interface ProgressionStepProps extends HTMLAttributes<HTMLDivElement> {
    step: number,
    state: ProgressionStepState,
    progression?: number,
    hasProgression?: boolean,
    orientation?: ProgressOrientation,
    onClickStep?: (step: number) => void
    stepNameOverwrite?: string | ReactElement
    description?: string | ReactElement
}

/**
 * This component represents a single progression step, i.e., the number of a specific step and its progression bar.
 * This progression bar can also be hidden by setting hasProgression === false. This is useful for e.g. the last step.
 *
 * @param step the step number that this progression bar corresponds to (starting at 0, but drawn starting at 1)
 * @param state the state of the progression:
 *      - completed: this step was already completed and will be colored
 *      - active: this is the currently active step. Its progression bar will be partially colored, according to the
 *                  progression property
 *      - future: this step is not completed yet. It will be gray.
 * @param progression the percentage of the progression of this component (50 := 50%). ONLY USED IF state === 'current'
 * @param hasProgression whether the progression bar for this component should be drawn (true) or not (false).
 *      Default: true.
 * @param orientation the orientation of the progression step (DEFAULT: horizontal):
 *      - horizontal: from left to right
 *      - vertical: from top to bottom
 * @param onClickStep callback function that is called when this step number is called.
 *      The current step number is passed to the callback function.
 *      If a function is provided (!== undefined), the styling is updated to symbolize that the number is clickable
 * @param stepNameOverwrite (optional) overwrites the rendered name inside the step button.
 * @param description (optional) add a descriptive text underneath the step number
 * @constructor
 */
export function ProgressionStep({
                                    step,
                                    state,
                                    progression,
                                    hasProgression,
                                    orientation,
                                    onClickStep,
                                    stepNameOverwrite,
                                    description
                                }: ProgressionStepProps) {
    const showProgression = hasProgression ?? true // default value == true
    const [shownProgression, setShownProgression] = useState<number>(0)
    const [stepsClickable, setStepsClickable] = useState<boolean>(onClickStep !== undefined)

    useEffect(() => {
        setStepsClickable(onClickStep !== undefined)
    }, [onClickStep])

    useEffect(() => {
        switch (state) {
            case "completed":
                setShownProgression(100)
                break
            case "active":
                setShownProgression(progression ?? 0)
                break
            case "future":
                setShownProgression(0)
                break
        }
    }, [state, progression])

    const handleClickStepNumber = () => {
        if (onClickStep !== undefined) {
            onClickStep(step)
        }
    }

    let progressionStyle: CSSProperties;
    if (orientation === 'horizontal') {
        progressionStyle = {
            width: `${shownProgression}%`
        }
    } else {
        progressionStyle = {
            height: `${shownProgression}%`
        }
    }

    return <div className={`ProgressionStep ${state} ${orientation ?? 'horizontal'}`}>
        <div className={`step-number ${state} ${stepsClickable ? 'clickable' : ''}`} onClick={handleClickStepNumber}>
            {stepNameOverwrite ?? (step + 1)}
        </div>
        {
            showProgression &&
            <div className={`progression-bar ${state} ${orientation ?? 'horizontal'}`}>
                <div className={`current-progression ${state} ${orientation ?? 'horizontal'}`}
                     style={progressionStyle}
                />
            </div>
        }
        {
            description !== undefined &&
            <div className={`description ${state}`}>
                <div>
                    {description}
                </div>
            </div>
        }
    </div>
}

export default Progress;