////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////// Methods and Constants for special case of offendersAge ////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////
import {LogicValue} from "../types/swishAPI";

export const OFFENDERS_AGE_DISPLAY_NAME = "Möglicher Täter strafmündig (>= 14 Jahre)"
export const AGE_OF_CRIMINAL_RESPONSIBILITY = 14

/**
 * This function transform the special age value into a logic value.
 * This logic value returns whether a person with this age is criminal responsible according to german law.
 * NOTE: In germany, everybody that is 14 years or older is criminal responsible.
 *
 * @param age the age that will be transformed into a logic value
 */
export function ageToCriminalResponsibility(age: number): LogicValue {
    if (age === UNKNOWN_AGE) {
        return 'unknown'
    }
    if (age >= AGE_OF_CRIMINAL_RESPONSIBILITY) {
        return 'true'
    }
    return 'false'
}

export const RESPONSIBLE_AGE = 98
export const UNKNOWN_AGE = 99
export const NOT_RESPONSIBLE_AGE = 12

/**
 * This function transform a logic value into an age. It can be seen as the inverse function of ageToCriminalResponsibility.
 * It returns an age < 14 iff isCriminalResponsible === 'false' and an age >= 14 otherwise.
 *
 * If the provided chatGPTAge meets this requirement, it is returned. Otherwise, default ages are returned.
 *
 * @param isCriminalResponsible whether an age of a person that is criminal responsible or not should be returned
 * @param chatGPTAge the age that was provided by ChatGPT. It should be used if it meets the requirements.
 */
export function criminalResponsibilityToAge(isCriminalResponsible: LogicValue, chatGPTAge: number) {
    if (isCriminalResponsible === ageToCriminalResponsibility(chatGPTAge)) {
        return chatGPTAge
    }

    switch (isCriminalResponsible) {
        case "true":
            return RESPONSIBLE_AGE
        case "false":
            return NOT_RESPONSIBLE_AGE
        case "unknown":
        case "nonsensical":
            return UNKNOWN_AGE
    }
}