import axios from "axios";
import {useCallback, useMemo} from "react";
import {TweetWithContext} from "../types/tweet";
import mockNitterConnector from "../test/mockNitterConnector";
import {useAuth} from "react-oidc-context";

/**
 * This is the connector to the nitter scraper web service
 */
export const useNitterConnector = () => {
    const auth = useAuth()

    const connection = useMemo(() => axios.create({
        baseURL: process.env.REACT_APP_NITTER_CONNECTOR_URL,
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        }
    }), [auth.user])

    // mock the connector if appropriate flag is set
    if (process.env.REACT_APP_MOCK_NITTER_CONNECTOR !== '0') {
        mockNitterConnector(connection)
    }

    const getTweetData = useCallback(async (tweetPath: string, abortController: AbortController | null = null): Promise<TweetWithContext> => {
        let response = await connection.get(
            `/x${tweetPath}`,
            {
                signal: abortController?.signal,
                validateStatus: status => status < 400
            },
        );
        let tweetData: TweetWithContext = response.data
        tweetData.mainTweet.path = tweetPath
        return tweetData
    }, [connection])

    return useMemo(() => {
        return {getTweetData}
    }, [getTweetData])
}