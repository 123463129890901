import "./ExplanationList.scss"
import React, {HTMLAttributes} from 'react';
import {FaSection} from "react-icons/fa6";
import IconList from "../IconList/IconList";

interface ExplanationListProps extends HTMLAttributes<HTMLUListElement> {
    explanations: string[]
}

/**
 * Visual representation of a bullet point list that explains a legal term.
 *
 * It renders as an unordered list by using the {@see IconList} component.
 *
 * @param explanations array of bullet points
 * @param props other properties that are directly passed to the enclosing unordered list.
 * @constructor
 */
function ExplanationList({explanations, ...props}: ExplanationListProps) {
    const className = 'ExplanationList ' + (props.className ?? '')

    const icon = <FaSection aria-label={'Paragraph'}/>

    return <IconList className={className}
                     bullet={icon}
                     elements={explanations.map(explanation => <span>
                         {explanation}
                     </span>)}
    />
}

export default ExplanationList;