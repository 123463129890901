import {createContext} from "react";
import {IntersectionBotBackendConnectorType} from "../hooks/juriCoreHooks/useIntersectionalBot";
import {JunBotResult, JunBotQuestion} from "../types/junbot";
import {AxiosResponse} from "axios";
import {JuriNodeId} from "../types/juricore";

export const IntersectionalBotCTX = createContext<IntersectionBotBackendConnectorType>({
    getQuestion: (questionId: JuriNodeId) => {
        console.error("Using dummy implementation of intersectional bot connector. Please provide an actual implementation as given from the useIntersectionalBot hook.")
        return new Promise<JunBotQuestion>(() => {})
    },
    getQuestions: (status?, showState?, abortSignal?) => {
        console.error("Using dummy implementation of intersectional bot connector. Please provide an actual implementation as given from the useIntersectionalBot hook.")
        return new Promise<JunBotQuestion[]>(() => {})
    },
    getResponseOptionIds: (question) => {
        console.error("Using dummy implementation of intersectional bot connector. Please provide an actual implementation as given from the useIntersectionalBot hook.")
        return new Promise(() => {})
    },
    getResponseOption: (responseOptionId) => {
        console.error("Using dummy implementation of intersectional bot connector. Please provide an actual implementation as given from the useIntersectionalBot hook.")
        return new Promise(() => {})
    },
    setQuestionStatus: (questionId, newStatus) => {
        console.error("Using dummy implementation of intersectional bot connector. Please provide an actual implementation as given from the useIntersectionalBot hook.")
        return new Promise<AxiosResponse<void, any>>(() => {})
    },
    answerMultipleChoiceQuestion: (multipleChoiceQuestion: JunBotQuestion,
                                   allResponseOptionIds: string[],
                                   checkedResponseOptionIds: string[]) => {
        console.error("Using dummy implementation of intersectional bot connector. Please provide an actual implementation as given from the useIntersectionalBot hook.")
        return new Promise(() => {})
    },
    getResults: (status?, showState?) => {
        console.error("Using dummy implementation of intersectional bot connector. Please provide an actual implementation as given from the useIntersectionalBot hook.")
        return new Promise<JunBotResult[]>(() => {})
    },
    juriCoreSID: "",
    reset: () => {
        return false
    }
})