import {useJuriCore} from "./useJuriCore";
import {JuriCoreKey, JuriCoreStatus, JuriNodeId} from "../../types/juricore";
import {
    JunBotResult,
    JunBotQuestion,
    JunBotQuestionTypes,
    JunBotResponseOption,
    parseNodeToResult,
    parseNodeToQuestion,
    parseNodeToResponseOption
} from "../../types/junbot";
import {AxiosResponse, GenericAbortSignal} from "axios";

/**
 * Expected show status of a node:
 *  - ONLY_SHOWN: show must be true
 *  - ONLY_HIDDEN: show must be false
 *  - ANY: show is ignored
 */
export enum NodeShowState {
    ONLY_SHOWN,
    ONLY_HIDDEN,
    ANY
}

export type IntersectionBotBackendConnectorType = {
    getQuestion: (questionId: JuriNodeId) => Promise<JunBotQuestion>
    getQuestions: (status?: JuriCoreStatus, showState?: NodeShowState, abortSignal?: GenericAbortSignal) => Promise<JunBotQuestion[]>
    setQuestionStatus: (questionId: string, newStatus: JuriCoreStatus) => Promise<AxiosResponse<void, any>>
    answerMultipleChoiceQuestion: (multipleChoiceQuestion: JunBotQuestion,
                                   allResponseOptionIds: string[],
                                   checkedResponseOptionIds: string[]) => Promise<AxiosResponse<void, any>>
    getResponseOptionIds: (question: JunBotQuestion) => Promise<string[]>
    getResponseOption: (responseOptionId: string) => Promise<JunBotResponseOption>
    getResults: (status: JuriCoreStatus, showState?: NodeShowState, abortSignal?: GenericAbortSignal) => Promise<JunBotResult[]>
    juriCoreSID: string

    reset: () => boolean
}


export const useIntersectionalBot = (onChange?: () => void): IntersectionBotBackendConnectorType => {
    if (process.env.REACT_APP_JURICORE_INTERSECTION_BOT_GRAPH_NAME === undefined) {
        throw new Error("No JunBot set for the intersectional bot. Please specify the REACT_APP_JURICORE_INTERSECTION_BOT_GRAPH_NAME environmental variable during compile time :)")
    }
    const GRAPH_NAME = process.env.REACT_APP_JURICORE_INTERSECTION_BOT_GRAPH_NAME

    const juriCore = useJuriCore()

    const getQuestion = async (questionId: JuriNodeId) => {
        let rawNode = await juriCore.getNode(questionId)
        return parseNodeToQuestion(rawNode)
    }

    const getQuestions = async (status?: JuriCoreStatus,
                                showState?: NodeShowState,
                                abortSignal?: GenericAbortSignal): Promise<JunBotQuestion[]> => {
        let rawNodes = await juriCore.getNodes(GRAPH_NAME, ['Frage'], status, abortSignal);
        let questions: JunBotQuestion[] = rawNodes.map(parseNodeToQuestion);
        if (showState === NodeShowState.ONLY_SHOWN) {
            return questions.filter(question => question.values.show)
        }
        if (showState === NodeShowState.ONLY_HIDDEN) {
            return questions.filter(question => !question.values.show)
        }
        return questions
    }

    const getResponseOptionIds = (question: JunBotQuestion): Promise<string[]> => {
        return juriCore.getNeighbors(question._id, ['has_member'])
    }

    const getResponseOption = async (responseOptionId: string): Promise<JunBotResponseOption> => {
        let rawNode = await juriCore.getNode(responseOptionId);
        return parseNodeToResponseOption(rawNode)
    }

    const setQuestionStatus = async (questionId: string, newStatus: JuriCoreStatus) => {
        let response = await juriCore.setNodeState(questionId, newStatus);
        if (onChange !== undefined) onChange();
        return response
    }

    const answerMultipleChoiceQuestion = async (multipleChoiceQuestion: JunBotQuestion,
                                                allResponseOptionIds: string[],
                                                checkedResponseOptionIds: string[]) => {
        if (multipleChoiceQuestion.values.type !== JunBotQuestionTypes.MultipleChoice) {
            throw new Error(`Tried to answer question with key '${multipleChoiceQuestion._key}' as multiple choice question, but its type is '${multipleChoiceQuestion.values.type}'`)
        }

        const batchedUpdate: Record<JuriCoreKey, JuriCoreStatus> = {}

        // set question node to status 1
        batchedUpdate[multipleChoiceQuestion._id] = 1

        // register the update for every response depending on whether it is checked or not
        for (const responseOptionId of allResponseOptionIds) {
            if (checkedResponseOptionIds.includes(responseOptionId)) {
                batchedUpdate[responseOptionId] = 1
            } else {
                batchedUpdate[responseOptionId] = -1
            }
        }

        let response = await juriCore.setNodeStateBatched(batchedUpdate);
        if (onChange !== undefined) onChange();
        return response
    }

    const getResults = async (status: JuriCoreStatus, showState?: NodeShowState, abortSignal?: GenericAbortSignal): Promise<JunBotResult[]> => {
        let rawNodes = await juriCore.getNodes(GRAPH_NAME, ['Ergebnis'], status, abortSignal);
        let answers: JunBotResult[] = rawNodes.map(parseNodeToResult);
        if (showState === NodeShowState.ONLY_SHOWN) {
            return answers.filter(answer => answer.values.show)
        }
        if (showState === NodeShowState.ONLY_HIDDEN) {
            return answers.filter(answer => !answer.values.show)
        }
        return answers
    }

    const reset = (): boolean => {
        const resetResult = juriCore.reset()
        if (onChange !== undefined) onChange()
        return resetResult
    }

    return {
        getQuestion,
        getQuestions,
        getResponseOptionIds,
        getResponseOption,
        setQuestionStatus,
        answerMultipleChoiceQuestion,
        getResults,
        juriCoreSID: juriCore.juriCoreSID,
        reset
    }
}