import "./Result.css"
import React from 'react';
import {ViewMessageProps} from "../ViewMessage";
import {MessageType} from "../../IntersectionalBot";
import JunBotMarkdown from "../../../JunBotMarkdown/JunBotMarkdown";

interface ResponseProps extends ViewMessageProps {

}

function Result({message, ...props}: ResponseProps) {
    const className = 'Response Message-bot ' + (props.className ?? '')

    if (message.msgType !== MessageType.Result) {
        return <div>Error: trying to display a message of type '${message.msgType}' as result</div>
    }

    // sort results in descending priority
    message.results.sort((a, b) => b.values.priority - a.values.priority)

    return (
        <div {...props} className={className}>
            {
                message.results.map(result => <JunBotMarkdown>{result.values.answer}</JunBotMarkdown>)
            }
        </div>
    )
}

export default Result;