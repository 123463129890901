import "./BotIcon.scss"
import React, {HTMLAttributes} from 'react';
import {FaGear, FaRobot} from "react-icons/fa6";

interface BotIconProps extends HTMLAttributes<HTMLDivElement> {
    isLoading?: boolean,
    name?: string,
}

function BotIcon({isLoading, name, ...props}: BotIconProps) {

    return <div {...props} className={'BotIcon ' + props.className ?? ''}>
        <div className={'roboter'}>
            <FaRobot aria-label={'Roboter'} className={'roboter-icon'}/>
            {
                isLoading && <div className={'gear'}>
                    <FaGear aria-label={'lädt'} className={'gear-icon rotating'}/>
                </div>
            }
        </div>
        {
            name !== undefined &&
            <div className={'label'}>
                {name}
            </div>
        }
    </div>
}

export default BotIcon;