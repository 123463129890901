import "./LogicValuePropertyIcon.css"
import React, {HTMLAttributes} from 'react';
import {LogicValue} from "../../../types/swishAPI";
import {FaCheck, FaQuestion, FaX} from "react-icons/fa6";

interface LogicValuePropertyIconProps extends HTMLAttributes<HTMLDivElement> {
    value: LogicValue
}

function LogicValuePropertyIcon({value, ...props}: LogicValuePropertyIconProps) {
    const className = 'LogicValuePropertyIcon ' + (props.className ?? '')

    switch (value) {
        case "true":
            return <FaCheck aria-label={'Gegeben'}/>
        case "unknown":
            return <FaQuestion aria-label={'Unbekannt'}/>
        case "false":
            return <FaX aria-label={'Nicht gegeben'}/>
    }

    return <div {...props} className={className}>

    </div>
}

export default LogicValuePropertyIcon;