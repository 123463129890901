import "./ViewPropertyArray.scss"
import React, {HTMLAttributes, ReactElement, useEffect, useState} from 'react';
import {ArrayPropertyDescription, GroupPropertyDescription} from "../../../../../types/swishAPI";
import _ from "lodash";
import ViewPropertyGroup from "../ViewPropertyGroup/ViewPropertyGroup";
import ExpandButton from "../../../../ExpandButton/ExpandButton";
import SimplePagination from "../../../../SimplePagination/SimplePagination";
import {useSubsumptionResult} from "../../../../../hooks/twitterDataHooks/useSubsumptionResult";

interface ViewPropertyArrayProps extends HTMLAttributes<HTMLDivElement> {
    propertyKey: string
    propertyDescription: ArrayPropertyDescription
    showAdvanced: boolean
    editable?: boolean
}

function ViewPropertyArray({
                               propertyKey,
                               propertyDescription,
                               showAdvanced,
                               editable,
                               ...props
                           }: ViewPropertyArrayProps) {
    const [array, setArray] = useState<any[]>([])
    // index of the currently displayed array element
    const [curIndex, setCurIndex] = useState<number>(0)
    const [expanded, setExpanded] = useState<boolean>(false)
    const [expandable, setExpandable] = useState<boolean>(true)
    const {subsumptionResult} = useSubsumptionResult()
    const isEditable = editable ?? false

    useEffect(() => {
        const arrayValue = subsumptionResult.getValue(propertyKey)
        if (!_.isArray(arrayValue)) {
            console.error(`Wrongly formatted ChatGPT response! Array expected, but '${arrayValue}' provided.`)
            return
        }
        setArray(arrayValue)
    }, [subsumptionResult, propertyKey])

    useEffect(() => {
        setExpandable(array.length > 0)
    }, [array])

    // =======================================================================================================
    // =======================================================================================================
    // ================================  Building the Body (= actual content) ================================
    // =======================================================================================================
    let bodyContent: ReactElement;
    // transform the displayed children in entries to iterate over them and count the children
    if (expanded && expandable) {
        // ==================================== Case 1: Expanded =============================================
        // we use an artificial group to display the properties of the current item. We set displayName === '' to
        // hide the heading and indentation
        const artificialGroup: GroupPropertyDescription = {
            displayName: '', // to suppress the display of the heading completely
            description: undefined, // irrelevant
            type: 'Group',
            advanced: propertyDescription.advanced,
            children: propertyDescription.children
        }

        bodyContent = <div className={`ViewPropertyArray-Item`}>
            <ViewPropertyGroup propertyDescription={artificialGroup}
                               showAdvanced={showAdvanced}
                               groupKey={`${propertyKey}[${curIndex}]`}
                               className={'properties'}
                               editable={isEditable}
            />
        </div>
    } else if (!expanded && expandable) {
        // ==================================== Case 2: Collapsed ============================================
        bodyContent = <div className={`ViewPropertyArray-Item properties-hidden-note clickable`}
                           onClick={() => setExpanded(true)}
        >
            [...] {array.length} {propertyDescription.displayName} ausgeblendet. Klicken zum einblenden.
        </div>
    } else {
        // ==================================== Case 3: No children ==========================================
        bodyContent = <div className={`ViewPropertyArray-Item properties-hidden-note`}>
            Keine {propertyDescription.displayName}
        </div>
    }

    // =======================================================================================================
    // =======================================================================================================
    // ===================================  RETURN  ==========================================================
    // =======================================================================================================
    return (
        <div {...props} className={'ViewPropertyArray ' + props.className ?? ''}>
            <div className={'header'}>
                <div className={'header-title '} onClick={() => setExpanded(!expanded)}>
                    <h5>
                        {propertyDescription.displayName}
                    </h5>
                    {expandable && <ExpandButton expanded={expanded} setExpanded={setExpanded}/>}
                </div>
                <div>{propertyDescription.description}</div>
            </div>
            <div className={`body indent`}>
                {
                    expanded && expandable &&
                    <SimplePagination curPage={curIndex} numPages={array.length} gotoPage={setCurIndex}/>
                }
                {bodyContent}
                <div className={'bottom-indent-separator'} role={'presentation'}/>
            </div>
        </div>
    )
}

export default ViewPropertyArray;