export const TWEET_PROMPT_TEMPLATE =
    `Prüfe folgenden Tweet anhand der oben genannten Kriterien:

{{USERNAME}}:
{{TWEET}}
`

export const TWEET_WITH_CONTEXT_PROMPT_TEMPLATE = TWEET_PROMPT_TEMPLATE +
    `
=====================================================
Zur besseren Einordnung des Tweets ist hier der Thread auf den der Tweet geantwortet hat:

{{CONTEXT}}
`