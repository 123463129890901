import "./ViewLogicValue.scss"
import React, {HTMLAttributes} from 'react';
import {LogicValue} from "../../../../../../types/swishAPI";
import LogicValuesButton from "../../../../../LogicValuesButton/LogicValuesButton";
import {useSubsumptionResult} from "../../../../../../hooks/twitterDataHooks/useSubsumptionResult";
import LogicValuePropertyIcon from "../../../../../icons/LogicValuePropertyIcon/LogicValuePropertyIcon";
import BotIcon from "../../../../../icons/BotIcon/BotIcon";

export interface ViewLogicValuePropertyProps extends HTMLAttributes<HTMLDivElement> {
    value: LogicValue,
    chatGPTValue: LogicValue,
    onValueUpdate: (newValue: LogicValue) => void,
    editable?: boolean
}

/**
 * This component renders a property value of type logic value. It renders the LogicValueButtons together with the
 * correct button labels (including the icon for the ChatGPT value).
 *
 * @param value the current LogicValue
 * @param chatGPTValue the LogicValue set by ChatGPT
 * @param onValueUpdate callback to update the value manually
 * @param editable if this view is editable, i.e., the value may be changed by clicking the buttons.
 * @param props other properties that are directly passed to the enclosing div.
 * @constructor
 */
function ViewLogicValue({
                            value,
                            chatGPTValue,
                            onValueUpdate,
                            editable,
                            ...props
                        }: ViewLogicValuePropertyProps) {
    const {subsumptionResult} = useSubsumptionResult()
    const isEditable = editable ?? false

    // we build custom components for the true/unknown/false buttons that display a roboter icon on the answer given by ChatGPT
    const trueComponent = <div className={'logic-value-button'}>
        <LogicValuePropertyIcon value={"true"}/>
        {chatGPTValue === 'true' && <BotIcon isLoading={subsumptionResult.isLoading}/>}
    </div>

    const unknownComponent = <div className={'logic-value-button'}>
        <LogicValuePropertyIcon value={"unknown"}/>
        {
            (chatGPTValue === 'unknown' || chatGPTValue === 'nonsensical') &&
            <BotIcon isLoading={subsumptionResult.isLoading}/>
        }
    </div>

    const falseComponent = <div className={'logic-value-button'}>
        <LogicValuePropertyIcon value={"false"}/>
        {chatGPTValue === 'false' && <BotIcon isLoading={subsumptionResult.isLoading}/>}
    </div>

    return (
        <div {...props} className={'ViewLogicValueProperty'}>
            <LogicValuesButton value={value as LogicValue}
                               onClickValue={onValueUpdate}
                               true={trueComponent}
                               unknown={unknownComponent}
                               false={falseComponent}
                               clickable={isEditable}
            />
        </div>
    )
}

export default ViewLogicValue;