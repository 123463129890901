import {FactDescriptionInput} from "../../types/types";
import {useContext} from "react";
import {HatespeechDataCTX} from "../../context/HatespeechDataCTX";

export type useInputType = [
    FactDescriptionInput | null,
    (newInput: FactDescriptionInput | null) => void
]

export const useFactDescription = (): useInputType => {
    const dataCTX = useContext(HatespeechDataCTX)

    return [dataCTX.input.data, dataCTX.input.setInput]
}