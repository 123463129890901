import React, {useState} from 'react';
import './App.scss';
import {APIContext} from "./context/APIContext";
import {Outlet} from "react-router-dom";
import {CookiesProvider} from "react-cookie";
import SSOProtected from "./components/auth/SSOProtected/SSOProtected";
import Header from "./components/Header/Header";
import {Settings} from "./context/Settings";
import Footer from "./components/Footer/Footer";
import {LogicValue} from "./types/swishAPI";

function App() {
    const [apiUUID, setApiUUID] = useState<string>("")
    const [expertMode, setExpertMode] = useState<LogicValue>("unknown")

    let mainContent = <Outlet/>

    return (
        <APIContext.Provider value={{
            uuid: apiUUID,
            setUUID: setApiUUID
        }}>
            <Settings.Provider value={{
                expertMode,
                setExpertMode
            }}>
                <CookiesProvider>
                    <div className="App">
                        <Header/>
                        <div className={'Main'}>
                            <SSOProtected loginRequired={process.env.REACT_APP_AUTH_REQUIRED !== 'false'}>
                                {mainContent}
                            </SSOProtected>
                        </div>
                        <Footer className={'Footer'}/>
                    </div>
                </CookiesProvider>
            </Settings.Provider>
        </APIContext.Provider>
    );
}

export default App;
