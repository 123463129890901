import {SubsumptionResultView, HatespeechDataCTX} from "../../context/HatespeechDataCTX";
import {useContext} from "react";
import {ChatGPTResponseValueType} from "../../context/ChatGPTResponseCTX";
import {ChatGPTResponse} from "../../types/swishAPI";

export type useSubsumptionResultType = {
    subsumptionResult: SubsumptionResultView,
    setLLMResult: (llmResult: ChatGPTResponse) => void,
    overwriteValue: (keyPath: string, value: ChatGPTResponseValueType) => void,
    setIsLoading: (isLoading: boolean) => void
}

export const useSubsumptionResult = (): useSubsumptionResultType => {
    const twitterCTX = useContext(HatespeechDataCTX)

    return {
        subsumptionResult: twitterCTX.subsumption.result,
        setLLMResult: twitterCTX.subsumption.setLLMResult,
        overwriteValue: twitterCTX.subsumption.overwriteSubsumptionValue,
        setIsLoading: twitterCTX.subsumption.setIsLoading
    }
}