import "./JunBotMarkdown.css"
import React, {HTMLAttributes} from 'react';
import Markdown, {Options} from "react-markdown";

interface JunBotMarkdownProps extends Options {
}

function JunBotMarkdown({children, ...props}: JunBotMarkdownProps) {
    const className = 'JunBotMarkdown ' + (props.className ?? '')

    return <Markdown {...props} className={className} components={{
        a: CustomLink
    }}>
        {children}
    </Markdown>
}

function CustomLink(props: HTMLAttributes<HTMLAnchorElement>) {
    return <a {...props} target={'_blank'}>
        {props.children}
    </a>
}

export default JunBotMarkdown;