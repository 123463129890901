import React, {HTMLAttributes, useState} from 'react';
import {hasAuthParams, useAuth} from "react-oidc-context";
import LoginRequired from "../LoginRequired/LoginRequired";



interface SSOProtectedProps extends HTMLAttributes<HTMLDivElement> {
    loginRequired: boolean
}

function SSOProtected({loginRequired, children, ...props}: SSOProtectedProps) {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    // automatically sign-in
    React.useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
            auth.signinSilent({
                redirect_uri: window.location.href
            });
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    // display nice messages while the authentication process is running
    switch (auth.activeNavigator) {
        case "signinRedirect":
            return <div>Anmeldung wird durchgeführt...</div>;
        case "signoutRedirect":
            return <div>Abmeldung wird durchgeführt</div>;
    }

    // display a login button if not authenticated
    if (!auth.isAuthenticated && loginRequired) {
        return <LoginRequired onLogin={() => void auth.signinRedirect({
            redirect_uri: window.location.href
        })}/>
    }

    if (auth.error) {
        console.error(auth.error)
    }

    return (
        <div {...props}>
            {children}
        </div>
    )
}

export default SSOProtected;