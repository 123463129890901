import {NavigateFunction, NavigateOptions, To, useNavigate} from "react-router-dom";

/**
 * This is an alternative to the useNavigate hook that maintains the current search params on navigation.
 * Note this does not work when specifying the navigation via a number (e.g. navigate(-1)).
 *
 * NOTE: it overwrites the normally set search params in the navigate call
 */
export const useNavigateMaintainSearchParams = () => {
    const navigate = useNavigate()

    const navigateSmart: NavigateFunction = (to: To | number, options: NavigateOptions = {}) => {
        if (typeof to === 'number') {
            navigate(to)
            return
        }

        const currentSearchParams = window.location.search

        if (typeof to === 'string') {
            navigate({
                pathname: to,
                search: currentSearchParams
            }, options)
            return;
        }

        navigate({
            pathname: to.pathname,
            search: currentSearchParams
        }, options)
    }

    return navigateSmart;
}