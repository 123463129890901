import "./ResponseOption.css"
import React, {HTMLAttributes, useState} from 'react';
import {JunBotResponseOption, STRING_LIST_SEPARATOR} from "../../../../../../types/junbot";
import {Form, Placeholder} from "react-bootstrap";
import {FaCircleQuestion} from "react-icons/fa6";
import ExampleList from "../../../../../customLists/ExampleList/ExampleList";

interface ResponseOptionProps extends HTMLAttributes<HTMLDivElement> {
    responseOption?: JunBotResponseOption
}

function DummyResponseOption({...props}: ResponseOptionProps) {
    const className = 'DummyResponseOption ' + (props.className ?? '')

    return (
        <div {...props} className={className}>
            <Placeholder as={"p"} animation={'glow'}>
                <Placeholder style={{width: "100%"}}/>
            </Placeholder>
        </div>
    )
}

function ResponseOption({responseOption, ...props}: ResponseOptionProps) {
    const className = 'ResponseOption ' + (props.className ?? '')
    const [showDescription, setShowDescription] = useState<boolean>(false)

    if (responseOption === undefined) {
        return <DummyResponseOption {...props}/>
    }

    const responseLabel = <div>
        <strong>{responseOption.values.name} </strong>
        {
            responseOption.values.description &&
            <FaCircleQuestion className={'clickable'}
                              onClick={(event) => {
                                  event.preventDefault()
                                  setShowDescription(!showDescription)
                              }}/>
        }
        {
            showDescription && <div>
                {responseOption.values.description ?? ""}
                <ExampleList type={'positive'}
                             examples={responseOption.values.positiveExamples?.split(STRING_LIST_SEPARATOR) ?? []}/>
                <ExampleList type={'negative'}
                             examples={responseOption.values.negativeExamples?.split(STRING_LIST_SEPARATOR) ?? []}/>
            </div>
        }
    </div>

    return (
        <div {...props} className={className}>
            <Form.Check
                key={responseOption._id}
                name={responseOption._id}
                id={responseOption._id}
                label={responseLabel}
            />
        </div>
    )
}

export default ResponseOption;