import "./ViewMessage.scss"
import React, {HTMLAttributes} from 'react';
import {AnswerMessage, Message, MessageType} from "../IntersectionalBot";
import Question from "./questions/Question";
import Answer from "./answers/Answer";
import Result from "./result/Result";

export interface ViewMessageProps extends HTMLAttributes<HTMLDivElement> {
    message: Message
    onCreateAnswer?: (answer: AnswerMessage) => void
}

function ViewMessage({message, onCreateAnswer, ...props}: ViewMessageProps) {
    const className = 'Message ' + (props.className ?? '')

    if (message.msgType === MessageType.Question) {
        return <Question {...props}
                         key={message.id}
                         question={message.question}
                         className={className}
                         onCreateAnswer={onCreateAnswer}
        />
    }

    if (message.msgType === MessageType.Answer) {
        return <Answer answer={message}/>
    }

    if (message.msgType === MessageType.Result) {
        return <Result {...props} message={message}/>
    }

    return <div {...props} className={className}>
        Something went wrong visualizing this message...
    </div>
}

export default ViewMessage;