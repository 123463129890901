import {useContext} from "react";
import {Settings} from "../../context/Settings";

export type useExpertModeType = [
    boolean,
    (newExpertMode: boolean) => void
]

/**
 * This hook provides the expert mode setting from the settings context.
 */
export const useExpertMode = (): useExpertModeType => {
    const settings = useContext(Settings)
    const expertMode = settings.expertMode === 'true'

    const setExpertMode = (newExpertMode: boolean) => {
        settings.setExpertMode(newExpertMode ? 'true' : 'false')
    }

    return [
        expertMode,
        setExpertMode
    ]
}