import "./AboutIsThisHatespeech.css"
import React, {HTMLAttributes} from 'react';
import chart from '../../../charts/pie-chart-error-rate.png'

interface AboutIsThisHatespeechProps extends HTMLAttributes<HTMLDivElement> {
}

const CHATGPT_ERROR_RATE: number = 13.50

function AboutIsThisHatespeech({...props}: AboutIsThisHatespeechProps) {
    const className = 'AboutIsThisHatespeech ' + (props.className ?? '')

    return <div {...props} className={className}>
        <p>
            Wir arbeiten stetig daran, isthishatespeech für Dich zu verbessern. Da sich isthishatespeech als Teil
            unseres Forschungsprojekts <a href={'https://juriskop.de'} target={'_blank'}>Juriskop</a> noch in der
            Entwicklung befindet, kann es jedoch zu Fehlern kommen – wir
            bitten um Dein Verständnis!
        </p>
        <p>
            Wir haben isthishatespeech anhand von 80 diversen Fällen getestet. Ausgewertet wurden Abweichungen
            fallentscheidender Merkmale zwischen unserer juristischen Einschätzung und der Analyse von ChatGPT. Unsere
            Auswertung ergab eine derzeitige <strong>Fehlerquote
            von {CHATGPT_ERROR_RATE.toLocaleString()} %</strong> (Stand: 18.07.2024).
        </p>
        <div className={'AboutIsThisHatespeech-chart-container'}>
            <img className={'AboutIsThisHatespeech-chart'} src={chart}
                 alt={`Piechart zur Fehlerrate von ${CHATGPT_ERROR_RATE.toLocaleString()} %`}/>
        </div>
        <p>
            Gerade arbeiten wir daran, mittels Prompt-Engineering und der Einbindung weiterer Large Language Models
            (sog. "LLMs") das Ergebnis zu präzisieren. Isthishatespeech kann und soll keine Rechtsberatung ersetzen.
        </p>
        <p>
            Fehlermeldungen nehmen wir gerne unter <a href={'mailto:info@juriskop.de'}>info@juriskop.de</a> entgegen.
        </p>
    </div>
}

export default AboutIsThisHatespeech;