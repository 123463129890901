import "./ViewChatGPTResult.scss"
import React, {HTMLAttributes} from 'react';
import {PropertyDescription} from "../../../../types/swishAPI";
import ViewSingleProperty from "./ViewSingleProperty/ViewSingleProperty";
import ViewPropertyGroup from "./ViewPropertyGroup/ViewPropertyGroup";
import ViewPropertyArray from "./ViewPropertyArray/ViewPropertyArray";

interface ViewChatGPTResultProps extends HTMLAttributes<HTMLDivElement> {
    propertyDescription: PropertyDescription,
    showAdvanced: boolean
    propertyKey?: string
    editable?: boolean
}

function ViewChatGPTResult({
                               propertyDescription,
                               showAdvanced,
                               propertyKey,
                               editable,
                               ...props
                           }: ViewChatGPTResultProps) {
    const isEditable = editable ?? false;

    if (propertyDescription.advanced && !showAdvanced) {
        return null;
    }

    switch (propertyDescription.type) {
        case "string":
        // fallthrough
        case "number":
        // fallthrough
        case "LogicValue":
            if (propertyKey === undefined) {
                throw new Error(`No key provided for property '${propertyDescription.displayName}' of type '${propertyDescription.type}'`)
            }
            return <div {...props}>
                <ViewSingleProperty propertyDescription={propertyDescription}
                                    propertyKey={propertyKey}
                                    key={propertyKey}
                                    editable={isEditable}
                />
            </div>
        case "Array":
            if (propertyKey === undefined) {
                throw new Error(`No key provided for property '${propertyDescription.displayName}' of type '${propertyDescription.type}'`)
            }
            return <div>
                <ViewPropertyArray propertyKey={propertyKey}
                                   propertyDescription={propertyDescription}
                                   showAdvanced={showAdvanced}
                                   key={propertyKey}
                                   editable={isEditable}
                />
            </div>
        case "Group":
            return <div>
                <ViewPropertyGroup groupKey={propertyKey}
                                   propertyDescription={propertyDescription}
                                   showAdvanced={showAdvanced}
                                   editable={isEditable}
                />
            </div>
    }
    return (
        <div {...props}>

        </div>
    )
}

export default ViewChatGPTResult;