import "./LoginRequired.scss"
import React, {HTMLAttributes} from 'react';
import LoginButton from "../LoginButton/LoginButton";

interface LoginPageProps extends HTMLAttributes<HTMLDivElement> {
    onLogin: () => void
}

function LoginRequired({onLogin, ...props}: LoginPageProps) {
    return (
        <div {...props} className={'LoginRequired ' + (props.className ?? '')}>
            <div className={'Main-content'}>
                <h1>Anmeldung erforderlich</h1>
                <p>
                    Dieser Bereich ist nur für angemeldete Nutzer zugänglich.
                    Bitte mit dem Kanzlei-Account anmelden.
                </p>
                <LoginButton onLogin={onLogin}/>
            </div>
        </div>
    )
}

export default LoginRequired;