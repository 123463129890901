import "./Input.css"
import React, {HTMLAttributes} from 'react';
import MultiModalInput from "../../../input/MultiModalInput/MultiModalInput";
import GeneralWorkflowDescription from "../../../descriptions/GeneralWorkflowDescription/GeneralWorkflowDescription";
import GeneralWorkflowDiagram from "../../../visualizations/GeneralWorkflowDiagram/GeneralWorkflowDiagram";
import FAQ from "../../../descriptions/FAQ/FAQ";
import AboutIsThisHatespeech from "../../../descriptions/AboutIsThisHatespeech/AboutIsThisHatespeech";

interface InputProps extends HTMLAttributes<HTMLDivElement> {
}

function Input({...props}: InputProps) {
    const className = 'Input ' + (props.className ?? '')

    return <div {...props} className={className}>
        <MultiModalInput/>

        <GeneralWorkflowDescription/>
        <div className={'GeneralWorkflowDiagram-container'}>
            <GeneralWorkflowDiagram/>
        </div>
        <AboutIsThisHatespeech/>
        <FAQ/>
    </div>
}

export default Input;