import "./IconList.scss"
import React, {HTMLAttributes, ReactElement} from 'react';

interface IconListProps extends HTMLAttributes<HTMLUListElement> {
    bullet: ReactElement
    elements: ReactElement[]
}

function IconList({bullet, elements, ...props}: IconListProps) {
    const className = 'IconList ' + (props.className ?? '')

    return <ul {...props} className={className}>
        {
            elements.map((element, index) => <li key={element.key ?? index}>
                <span className={'IconList-bullet'}>
                    {bullet}
                </span>
                {element}
            </li>)
        }
    </ul>
}

export default IconList;