import {createContext} from "react";
import {ChatGPTResponse, SwishAPIResponseJSON} from "../types/swishAPI";
import {ChatGPTResponseValueType} from "./ChatGPTResponseCTX";
import {SubsumptionResult} from "../classes/SubsumptionResult";
import {DEFAULT_CHATGPT_RESPONSE} from "../defaultValues/defaultChatGPTResponse";
import {defaultSWISHResponse} from "../defaultValues/defaultSWISHResponse";
import {FactDescriptionInput} from "../types/types";

export interface SubsumptionResultView {
    getValue: (keyPath: string) => ChatGPTResponseValueType,
    getChatGPTValue: (keyPath: string) => ChatGPTResponseValueType,
    applyOverwrites: () => ChatGPTResponse,
    isLoading: boolean,
    hasUpdatedLLMResponse: () => boolean
}

export interface HatespeechDataCTXType {
    input: {
        data: FactDescriptionInput | null,
        setInput: (input: FactDescriptionInput | null) => void,
    },
    subsumption: {
        result: SubsumptionResultView,
        setLLMResult: (llmResult: ChatGPTResponse) => void,
        overwriteSubsumptionValue: (keyPath: string, value: ChatGPTResponseValueType) => void,
        setIsLoading: (isLoading: boolean) => void,
    },
    evaluate: {
        result: SwishAPIResponseJSON | null,
        setResult: (newResult: SwishAPIResponseJSON) => void
    }
}

export const HatespeechDataCTX = createContext<HatespeechDataCTXType>({
    input: {
        data: null,
        setInput: () => {
        },
    },
    subsumption: {
        result: new SubsumptionResult(DEFAULT_CHATGPT_RESPONSE),
        setLLMResult: () => {
        },
        overwriteSubsumptionValue: () => {
        },
        setIsLoading: () => {
        },
    },
    evaluate: {
        result: defaultSWISHResponse,
        setResult: () => {}
    }
})