import {createContext} from "react";
import {LogicValue} from "../types/swishAPI";

export interface SettingsCtxType {
    expertMode: LogicValue,
    setExpertMode: (newExpertMode: LogicValue) => void
}

export const Settings = createContext<SettingsCtxType>({
    expertMode: "unknown",
    setExpertMode: () => {}
})