import "./TweetInput.scss"
import React, {ChangeEventHandler, FormEventHandler, HTMLAttributes, useEffect, useRef, useState} from 'react';
import {Form} from "react-bootstrap";
import {useNitterConnector} from "../../../../../hooks/useNitterConnector";
import {TweetWithContext} from "../../../../../types/tweet";
import TweetView from "../../../../Twitter/TweetView/TweetView";
import Feedback from "react-bootstrap/Feedback";
import ViewNetworkError from "../../../../ViewNetworkError/ViewNetworkError";
import {AxiosError} from "axios";
import {useSearchParams} from "react-router-dom";

export const ALLOWED_TWITTER_HOSTS = ["twitter.com", "x.com"]
export const TWEET_LINK_SEARCH_PARAM_KEX = 'xLink'

interface TweetInputProps extends HTMLAttributes<HTMLDivElement> {
    tweetData: TweetWithContext | null,
    setTweetData: (newTweetData: TweetWithContext | null) => void,
    onStartFetchTweet?: (tweetURL: URL) => void,
    onEndFetchTweet?: (successful: boolean) => void,
    onSubmit?: () => void
}

/**
 * This component displays an input field to input a Twitter link.
 * If a valid link was provided, it is fetched from the backend and displayed.
 * The actual Tweet data is maintained in the parent component.
 *
 * @param tweetData the actual tweet data, that should correspond to the current link
 * @param setTweetData setter function to update the tweet data if the link was updated.
 * @param onStartFetchTweet callback function that is called if the tweet data is being fetched. The URL of the tweet
 *      that is being fetched is passed to the callback.
 * @param onEndFetchTweet callback that is called when fetching the tweet data is finished. The state of the response
 *      is passed:
 *          - true: fetch was successful
 *          - false: an error occurred
 * @param onSubmit callback function that is called when the input is submitted
 * @param props
 * @constructor
 */
function TweetInput({tweetData, setTweetData, onStartFetchTweet, onEndFetchTweet, onSubmit, ...props}: TweetInputProps) {
    const [twitterLink, setTwitterLink] = useState<string>("")
    const [twitterLinkInvalid, setTwitterLinkInvalid] = useState<boolean>(false)
    const [isLoadingTweetData, setIsLoadingTweetData] = useState<boolean>(false)
    const [tweetLoadingError, setTweetLoadingError] = useState<AxiosError>()
    const [inputInvalid, setInputInvalid] = useState<boolean>(false)
    const [inputFeedbackText, setInputFeedbackText] = useState<string>("")
    const [searchParams, setSearchParams] = useSearchParams()

    const nitterConnector = useNitterConnector()
    const abortController = useRef<AbortController>(new AbortController())

    useEffect(() => {
        const newTweetLink = searchParams.get(TWEET_LINK_SEARCH_PARAM_KEX)
        if (newTweetLink !== null) {
            setTwitterLink(newTweetLink)
        }
    }, [searchParams])

    useEffect(() => {
        setTweetLoadingError(undefined) // new link => we do not know yet if it is impossible to load the tweet
        let tweetURL: URL
        try {
            tweetURL = new URL(twitterLink)
        } catch (e) {
            setTwitterLinkInvalid(true)
            return // no valid url => do nothing
        }

        if (!ALLOWED_TWITTER_HOSTS.includes(tweetURL.host)) {
            setTwitterLinkInvalid(true)
            return; // not a valid tweet link
        }

        setTwitterLinkInvalid(false)

        // abort previous requests as they are deprecated now
        abortController.current.abort()
        abortController.current = new AbortController()

        // update the state because tweet is now loading
        setIsLoadingTweetData(true)
        setTweetData(null)
        // call callback function to notify parent
        if (onStartFetchTweet !== undefined) {
            onStartFetchTweet(tweetURL)
        }

        nitterConnector.getTweetData(tweetURL.pathname, abortController.current).then(newTweetData => {
            setTweetData(newTweetData)
            setIsLoadingTweetData(false)
            setTweetLoadingError(undefined)
            if (onEndFetchTweet !== undefined) {
                onEndFetchTweet(true)
            }
        }).catch(error => {
            if (error.code === "ERR_CANCELED") {
                return // do nothing as it was just cancelled
            }

            console.error(error)
            setIsLoadingTweetData(false)
            setTweetData(null)
            setTweetLoadingError(error)
            if (onEndFetchTweet !== undefined) {
                onEndFetchTweet(false)
            }
        })
    }, [twitterLink, nitterConnector, setTweetData])

    useEffect(() => {
        checkInput()
    }, [tweetLoadingError])

    const handleUpdateTwitterLink: ChangeEventHandler<HTMLInputElement> = (event) => {
        searchParams.set(TWEET_LINK_SEARCH_PARAM_KEX, event.currentTarget.value)
        setSearchParams(searchParams)
    }

    const checkInput = () => {
        if (tweetLoadingError?.response?.status === 404 && !isLoadingTweetData) {
            setInputInvalid(true)
            setInputFeedbackText("Kein Tweet unter diesem Link gefunden.")
        } else if (twitterLinkInvalid) {
            setInputInvalid(true)
            setInputFeedbackText("Kein gültiger Link zu einem Tweet.")
        } else {
            setInputInvalid(false)
        }
    }

    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault()
        if (onSubmit === undefined) return

        onSubmit()
    }

    return (
        <div {...props}>
            <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Control value={twitterLink}
                                  placeholder={'Link zum Tweet'}
                                  onChange={handleUpdateTwitterLink}
                                  isValid={tweetData !== undefined}
                                  isInvalid={inputInvalid}
                                  onBlur={checkInput}
                    />
                    <Feedback type={'invalid'}>{inputFeedbackText}</Feedback>
                </Form.Group>
            </Form>
            <div className={'tweet-preview-container'}>
                <TweetView isLoading={isLoadingTweetData} tweetWithContext={tweetData}/>
                {
                    tweetLoadingError && tweetLoadingError.response?.status !== 404 && <ViewNetworkError
                        axiosError={tweetLoadingError}
                        errorMessageOverwrite={'Tweet kann aktuell nicht geladen werden. Bitte versuche es später erneut.'}
                    />
                }
            </div>
        </div>
    )
}

export default TweetInput;