import "./Footer.scss"
import React, {HTMLAttributes} from 'react';
import SocialButtons from "../SocialButtons/SocialButtons";

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
}

/**
 * This component renders the footer of this website.
 * It tries to imitate the footer of the juriskop.de website.
 * The only difference is, that it also shows the current version number.
 *
 * It is responsive.
 *
 * @param props
 * @constructor
 */
function Footer({...props}: FooterProps) {
    const className = 'Footer ' + (props.className ?? '')

    return <div {...props} className={className}>
        <div className={'Footer-address'}>
            <div style={{textTransform: 'uppercase'}}>
                Jun Rechtsanwälte
            </div>
            <div>
                Salvatorstraße 21
            </div>
            <div>
                97074 Würzburg
            </div>
            <a href={'mailto:info@kanzlei-jun.de'} className={'no-color'}>
                info@kanzlei-jun.de
            </a>
        </div>
        <div className={'Footer-cooperation'}>
            <div style={{textTransform: 'uppercase'}}>
                in Kooperation mit:
            </div>
            <div>
                <a href={'https://www.informatik.uni-wuerzburg.de/is/startseite/'}
                   className={'no-color no-underline'}>
                    Universität Würzburg
                </a>
            </div>
            <div>
                <a href={'https://www.informatik.uni-wuerzburg.de/is/startseite/'}
                   className={'no-color no-underline'}>
                    Lehrstuhl für Künstliche Intelligenz und Wissenssysteme
                </a>
            </div>
            <div>
                <a href={'https://www.informatik.uni-wuerzburg.de/is/startseite/'}
                   className={'no-color no-underline'}>
                    Prof. Dr. Frank Puppe
                </a>
            </div>
        </div>
        <div className={'Footer-compliance'}>
            <div>
                <a href={'https://juriskop.de/datenschutzerklaerung/'} className={'no-color'}>
                    Datenschutz
                </a>
            </div>
            <div>
                <a href={'https://www.junit.de/2020/impressum'} className={'no-color'}>
                    Impressum
                </a>
            </div>
        </div>
        <SocialButtons className={'Footer-socials'}/>
        <div className={'Footer-version'}>
            <div>
                Version: {process.env.REACT_APP_VERSION}
            </div>
        </div>
    </div>
}

export default Footer;