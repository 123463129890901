import axios, {AxiosResponse, GenericAbortSignal} from "axios";
import {JuriCoreStatus, JuriGraph, JuriNode, JuriNodeId, JuriNodeValues} from "../../types/juricore";
import {useCookies} from "react-cookie";
import {useState} from "react";
import axiosRetry from "axios-retry";

const JURICORE_SID_HEADER_NAME = "x-juricore-sid" as const;
const JURICORE_SID_COOKIE_NAME = "juricore-sid" as const;

export const useJuriCore = () => {
    const [cookies, setCookies] = useCookies()
    const [juriCoreSID, setJuriCoreSID] = useState<string>(cookies[JURICORE_SID_COOKIE_NAME])
    const connection = axios.create({
        baseURL: process.env.REACT_APP_JURICORE_URL,
        headers: {
            [JURICORE_SID_HEADER_NAME.toLowerCase()]: juriCoreSID
        }
    })

    axiosRetry(connection, {
        retries: 3,
        retryCondition: error => {
            return error.response === undefined || error.response.status >= 400;
        }
    })

    connection.interceptors.response.use((response) => {
        const newJuriCoreSID = response.headers[JURICORE_SID_HEADER_NAME]
        if (!newJuriCoreSID) {
            return response
        }
        setCookies(JURICORE_SID_COOKIE_NAME, newJuriCoreSID)
        setJuriCoreSID(newJuriCoreSID)
        return response
    })

    /**
     * Gets all nodes and edges of a given graph.
     *
     * @param graphName the name of the graph that should be returned. It is returned as Promise
     */
    const getGraph = async (graphName: string): Promise<JuriGraph> => {
        let response = await connection.get(
            `/graphs/${graphName}`
        );
        return response.data
    }

    /**
     * Get all nodes of a given graph.
     * You can filter the nodes by labels and their status.
     *
     * @param graphName the name of the graph from which the nodes are requested.
     * @param requiredLabels list of labels that muss ALL be included in the labels of a node to be returned by this function.
     * @param requiredStatus only nodes with this status are returned.
     * @param abortSignal an optional signal to register an abort controller to allow the abortion of the call
     */
    const getNodes = async (graphName: string,
                            requiredLabels?: string[],
                            requiredStatus?: JuriCoreStatus,
                            abortSignal?: GenericAbortSignal): Promise<JuriNode<JuriNodeValues>[]> => {
        let response = await connection.get(
            `/graphs/${graphName}/nodes`,
            {
                params: {
                    includeLabels: requiredLabels?.join(',')
                },
                signal: abortSignal
            }
        );
        let nodes: JuriNode<JuriNodeValues>[] = response.data as JuriNode<JuriNodeValues>[];
        if (requiredStatus === undefined) return nodes;
        nodes = nodes.filter(node => node.values.status === requiredStatus)
        return nodes
    }

    /**
     * Returns a list of the ids of the adjacent nodes of a given node.
     * Use filterEdgeTypes to restrict the types of edges that are traversed when searching for neighbors.
     *
     * @param nodeId the ID of the node in the respective graph of which the neighbors are requested
     * @param filterEdgeTypes array of edge types. Only edges with one of theses types are traversed when looking for
     *          neighbors.
     * @param abortSignal an optional signal to register an abort controller to allow the abortion of the call
     */
    const getNeighbors = async (nodeId: string,
                                filterEdgeTypes?: string[],
                                abortSignal?: GenericAbortSignal): Promise<string[]> => {
        let response = await connection.get(
            `/graphs/${nodeId}/neighbors`,
            {
                params: {
                    filterEdgeTypes: filterEdgeTypes?.join(',')
                },
                signal: abortSignal
            }
        );
        return response.data as string[];
    }

    const getNode = async (nodeId: string): Promise<JuriNode<JuriNodeValues>> => {
        let response = await connection.get(
            `graphs/${nodeId}`
        );
        return response.data as JuriNode<JuriNodeValues>;
    }

    const setNodeState = (nodeId: string, newStatus: JuriCoreStatus) => {
        return connection.patch(`/session/update/${nodeId}`, {
            status: newStatus
        }) as Promise<AxiosResponse<void, any>>
    }

    const setNodeStateBatched = (batchedUpdate: Record<JuriNodeId, JuriCoreStatus>) => {
        const updateObj: Record<JuriNodeId, Object> = {}

        for (const [nodeId, newStatus] of Object.entries(batchedUpdate)) {
            updateObj[nodeId] = {
                status: newStatus
            }
        }

        return connection.patch(`/session/update`, updateObj) as Promise<AxiosResponse<void, any>>
    }

    const reset = (): boolean => {
        setJuriCoreSID("")
        return true
    }

    return {getGraph, getNodes, getNeighbors, getNode, setNodeState, setNodeStateBatched, juriCoreSID, reset}
}