import "./SurveyLink.css";
import React, { HTMLAttributes } from "react";
import { Button } from "react-bootstrap";
import { FaClipboardList } from "react-icons/fa6";

interface SurveyLinkProps extends HTMLAttributes<HTMLDivElement> {}

function SurveyLink({ ...props }: SurveyLinkProps) {
  const className = "SurveyLink " + (props.className ?? "");

  return (
    <div {...props} className={className}>
      <h2>Du möchtest unsere Forschung unterstützen?</h2>

      <div className={"SurveyLink-content"}>
        <p>
          Dann beantworte bitte folgende anonyme Umfrage zum Nutzungserlebnis
          mit unserem Hatespeech-Bot (Dauer: ca. 2 Minuten).
        </p>
        <Button
          href={"https://survey.juriskop.de/index.php/163757?lang=de"}
          target={"_blank"}
          variant={"primary"}
        >
          Zur Umfrage <FaClipboardList />
        </Button>
      </div>
    </div>
  );
}

export default SurveyLink;
