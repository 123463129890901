import "./LoginButton.scss"
import React, {HTMLAttributes} from 'react';
import {Button} from "react-bootstrap";
import {useAuth} from "react-oidc-context";

interface LoginButtonProps extends HTMLAttributes<HTMLDivElement> {
    onLogin?: () => void
}

function LoginButton({onLogin, ...props}: LoginButtonProps) {
    const auth = useAuth()
    const handleLogin = () => {
        if (onLogin !== undefined) {
            onLogin()
        } else {
            auth.signinRedirect({
                redirect_uri: window.location.href
            })
        }
    }

    const className = 'LoginButton ' + (props.className ?? '')
    return (
        <div {...props} className={className}>
            <Button onClick={handleLogin}>
                Anmelden
            </Button>
        </div>
    )
}

export default LoginButton;