import "./GeneralWorkflowDescription.css"
import React, {HTMLAttributes} from 'react';

interface GeneralWorkflowDescriptionProps extends HTMLAttributes<HTMLDivElement> {
}

function GeneralWorkflowDescription({...props}: GeneralWorkflowDescriptionProps) {
    const className = 'GeneralWorkflowDescription ' + (props.className ?? '')

    return <div {...props} className={className}>
        <h1>Mit Künstlicher Intelligenz in den Kampf gegen Hatespeech!</h1>

        <p>
            In der heutigen, digital vernetzten Welt birgt das Phänomen Hatespeech – auch bekannt als Hassrede im digitalen Raum – zunehmend Gefahren sowohl für Betroffene als auch die Gesellschaft.
            Handelt es sich in Deinem Fall um eine rechtsverletzende <b>Beleidigung (§ 185 StGB)</b>, <b>Üble Nachrede (§ 186 StGB)</b> oder <b>Verleumdung (§ 187 StGB)</b>? Unser Bot verrät es Dir!
        </p>
    </div>
}

export default GeneralWorkflowDescription;