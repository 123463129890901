import {FactDescriptionInput} from "../../types/types";
import {TWEET_PROMPT_TEMPLATE, TWEET_WITH_CONTEXT_PROMPT_TEMPLATE} from "../../promptTemplates/tweetPromptTemplate";
import {TEXT_PROMPT_TEMPLATE} from "../../promptTemplates/textPromptTemplate";

/**
 * This function replaces specified placeholders with the corresponding values for a given prompt template.
 * Use this function whenever you want to expand the templates with actual values.
 *
 * @param promptTemplate the template that should be expanded
 * @param placeholderMapping a mapping of the placeholder keys and values that should be used to replace
 * the respective placeholder.
 */
const replacePromptPlaceholder = (promptTemplate: string, placeholderMapping: Record<string, string>): string => {
    let expandedTemplate = promptTemplate

    for (const [key, value] of Object.entries(placeholderMapping)) {
        expandedTemplate = expandedTemplate.replaceAll(`\{\{${key}\}\}`, value)
    }

    return expandedTemplate
}

export const generateFactDescription = (factDescriptionInput: FactDescriptionInput): string => {
    switch (factDescriptionInput.type) {
        // ============================ Tweet input ===================================
        case "tweet":
            const tweetData = factDescriptionInput.tweet

            let contextDescription = ""
            tweetData.context.forEach(context => {
                contextDescription += "\n"
                contextDescription += `${context.full_name}:`
                contextDescription += context.content
            })

            const templatePlaceholderMapping: Record<string, string> = {
                "USERNAME": tweetData.mainTweet.username,
                "TWEET": tweetData.mainTweet.content,
                "CONTEXT": contextDescription
            }

            if (tweetData.context.length > 0) {
                return replacePromptPlaceholder(TWEET_WITH_CONTEXT_PROMPT_TEMPLATE, templatePlaceholderMapping)
            }
            return replacePromptPlaceholder(TWEET_PROMPT_TEMPLATE, templatePlaceholderMapping)

        // =============================== Case description ==============================
        case "text":
            const text = factDescriptionInput.text
            return replacePromptPlaceholder(TEXT_PROMPT_TEMPLATE, {"TEXT": text})
    }

    return ""
}