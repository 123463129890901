import "./ResultLegend.css"
import React, {HTMLAttributes} from 'react';

interface ResultLegendProps extends HTMLAttributes<HTMLDivElement> {
}

function ResultLegend({...props}: ResultLegendProps) {
    const className = 'ResultLegend ' + (props.className ?? '')

    return <div {...props} className={className}>
        <div className={'ResultLegend-element'}>
            <div className={'ResultLegend-color result-background true'}
                 aria-label={'Grüne Farbe'}
                 role={'presentation'}
            />
            <div>Tatbestand könnte vorliegen (grün)</div>
        </div>
        <div className={'ResultLegend-element'}>
            <div className={'ResultLegend-color result-background false'}
                 aria-label={'Rote Farbe'}
                 role={'presentation'}
            />
            <div>Tatbestand könnte nicht vorliegen (rot)</div>
        </div>
        <div className={'ResultLegend-element'}>
            <div className={'ResultLegend-color result-background unknown'}
                 aria-label={'Graue Farbe'}
                 role={'presentation'}
            />
            <div>Auswertung ist unklar (grau)</div>
        </div>
    </div>
}

export default ResultLegend;