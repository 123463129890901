import {LogicValue} from "../../types/swishAPI";

export interface SingleResultTexts {
    heading: string,
    result: Map<LogicValue, string>,
    description: string,
    additionalInformation: {
        title: string,
        description: string
    }[]
}

export type ViewParagraphResultTextsType = {
    stgb185: SingleResultTexts,
    stgb186: SingleResultTexts,
    stgb187: SingleResultTexts,
}

const VIEW_PARAGRAPH_RESULT_TEXTS: ViewParagraphResultTextsType = {
    stgb185: {
        heading: "Beleidigung, § 185 StGB",
        result: new Map([
            ["true", "Unsere Auswertung des Inhalts ergibt, dass eine Beleidigung **vorliegen** könnte."],
            ["unknown", "Unsere Auswertung konnte zu keinem eindeutigen Ergebnis kommen."],
            ["nonsensical", "Unsere Auswertung konnte zu keinem schlüssigen Ergebnis kommen."],
            ["false", "Unsere Auswertung des Inhalts ergibt, dass eine Beleidigung wohl **nicht vorliegt**."],
        ]),
        description: `
            Eine Beleidigung verletzt Betroffene in ihren Rechten. 
            Die Beleidigung ist daher in Deutschland in § 185 StGB unter Strafe gestellt. 
            Gegen eine Beleidigung kannst du dich sowohl straf- als auch zivilrechtlich wehren.`,
        additionalInformation: [
            {
                title: "Was sagt das Gesetz zu der Beleidigung, § 185 StGB?",
                description: "Die Beleidigung wird mit Freiheitsstrafe bis zu einem Jahr oder mit Geldstrafe und, wenn die Beleidigung öffentlich, in einer Versammlung, durch Verbreiten eines Inhalts (§ 11 Absatz 3 StGB) oder mittels einer Tätlichkeit begangen wird, mit Freiheitsstrafe bis zu zwei Jahren oder mit Geldstrafe bestraft."
            }
        ]
    },
    stgb186: {
        heading: "Üble Nachrede, § 186 StGB",
        result: new Map([
            ["true", "Unsere Auswertung des Inhalts ergibt, dass eine Üble Nachrede  **vorliegen** könnte."],
            ["unknown", "Unsere Auswertung konnte zu keinem eindeutigen Ergebnis kommen."],
            ["nonsensical", "Unsere Auswertung konnte zu keinem schlüssigen Ergebnis kommen."],
            ["false", "Unsere Auswertung des Inhalts ergibt, dass eine Üble Nachrede  wohl **nicht vorliegt**."],
        ]),
        description: `
            Eine Üble Nachrede verletzt Betroffene in ihren Rechten. 
            Die Üble Nachrede ist daher in Deutschland in § 186 StGB unter Strafe gestellt. 
            Gegen eine Üble Nachrede kannst du dich sowohl straf- als auch zivilrechtlich wehren. 
            `,
        additionalInformation: [
            {
                title: "Was sagt das Gesetz zu der Üble Nachrede, § 186 StGB?",
                description: `
                    Wer in Beziehung auf einen anderen eine Tatsache behauptet oder verbreitet, welche denselben verächtlich zu machen oder in der öffentlichen Meinung herabzuwürdigen geeignet ist, wird, wenn nicht diese Tatsache erweislich wahr ist, mit Freiheitsstrafe bis zu einem Jahr oder mit Geldstrafe und, wenn die Tat öffentlich, in einer Versammlung oder durch Verbreiten eines Inhalts (§ 11 Absatz 3 StGB) begangen ist, mit Freiheitsstrafe bis zu zwei Jahren oder mit Geldstrafe bestraft. 
                `
            }
        ]
    },
    stgb187: {
        heading: "Verleumdung, § 187 StGB",
        result: new Map([
            ["true", "Unsere Auswertung des Inhalts ergibt, dass eine Verleumdung  **vorliegen** könnte."],
            ["unknown", "Unsere Auswertung konnte zu keinem eindeutigen Ergebnis kommen."],
            ["nonsensical", "Unsere Auswertung konnte zu keinem schlüssigen Ergebnis kommen."],
            ["false", "Unsere Auswertung des Inhalts ergibt, dass eine Verleumdung  wohl **nicht vorliegt**."],
        ]),
        description: `
            Eine Verleumdung verletzt Betroffene in ihren Rechten. 
            Die Verleumdung ist daher in Deutschland in § 187 StGB unter Strafe gestellt. 
            Gegen eine Verleumdung kannst du dich sowohl straf- als auch zivilrechtlich wehren.`,
        additionalInformation: [
            {
                title: "Was sagt das Gesetz zu der Verleumdung, § 187 StGB?",
                description: `
                    Wer in Beziehung auf einen anderen eine Tatsache behauptet oder verbreitet, welche denselben verächtlich zu machen oder in der öffentlichen Meinung herabzuwürdigen geeignet ist, wird, wenn nicht diese Tatsache erweislich wahr ist, mit Freiheitsstrafe bis zu einem Jahr oder mit Geldstrafe und, wenn die Tat öffentlich, in einer Versammlung oder durch Verbreiten eines Inhalts (§ 11 Absatz 3 StGB) begangen ist, mit Freiheitsstrafe bis zu zwei Jahren oder mit Geldstrafe bestraft.
                `
            }
        ]
    }
}

export default VIEW_PARAGRAPH_RESULT_TEXTS