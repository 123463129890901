import {useCallback, useContext} from "react";
import {HatespeechDataCTX} from "../../context/HatespeechDataCTX";
import {TweetWithContext} from "../../types/tweet";

export type useTweetDataType = [TweetWithContext | null, (tweet: TweetWithContext | null) => void]

export const useTweetData = (): useTweetDataType => {
    const twitterCTX = useContext(HatespeechDataCTX)

    const setTweet = useCallback((tweet: TweetWithContext | null) => {
        if (tweet === null) {
            twitterCTX.input.setInput(null)
            return
        }

        twitterCTX.input.setInput({
            type: 'tweet',
            tweet: tweet
        })
    }, [twitterCTX.input.setInput])

    if (twitterCTX.input.data?.type === 'tweet') {
        return [twitterCTX.input.data.tweet, setTweet]
    }

    return [null, setTweet]
}