import "./Question.scss"
import React, {HTMLAttributes} from 'react';
import {JunBotQuestion, JunBotQuestionTypes} from "../../../../types/junbot";
import YesNoQuestion from "./YesNoQuestion/YesNoQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion/MultipleChoiceQuestion";
import {FaSpinner} from "react-icons/fa";
import {AnswerMessage} from "../../IntersectionalBot";

export interface QuestionProps extends HTMLAttributes<HTMLDivElement> {
    question: JunBotQuestion
    onCreateAnswer?: (answer: AnswerMessage) => void
}

function Question({question, ...props}: QuestionProps) {
    const className = 'Question Message-bot ' + (props.className ?? '')

    if (question === undefined) {
        return <div {...props} className={className}>
            <FaSpinner className={'rotating'}/>
        </div>
    }

    switch (question.values.type) {
        case JunBotQuestionTypes.YesNo:
            return <YesNoQuestion {...props} question={question} className={className}/>
        case JunBotQuestionTypes.MultipleChoice:
            return <MultipleChoiceQuestion {...props} question={question} className={className}/>
    }

    return <div {...props} className={className}>
        Error displaying question with id '{question._id}': QuestionType '{question.values.type}' not known.
    </div>
}

export default Question;