import "./Header.scss"
import React, {HTMLAttributes} from 'react';
import ProfileIcon from "../auth/ProfileIcon/ProfileIcon";

interface HeaderProps extends HTMLAttributes<HTMLDivElement> {

}

/**
 * This component renders the header of this website.
 * It contains the logo, title and profile button.
 *
 * It is responsive.
 *
 * @param props
 * @constructor
 */
function Header({...props}: HeaderProps) {
    const className = 'Header ' + (props.className ?? '')
    return (
        <div {...props} className={className}>
            <div className={'Header-title'}>
                <a className={'no-color no-underline'}
                   href={'https://isthishatespeech.de'}
                >
                    <b>Juriskop</b>
                </a>
            </div>
            <div className={'Header-subtitle'} lang={'en'}>
                <b><i>Is this hatespeech?</i></b>
            </div>
            <div className={'Header-profile'}>
                <ProfileIcon/>
            </div>
        </div>
    )
}

export default Header;