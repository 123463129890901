import "./FetchTweet.scss"
import React, {HTMLAttributes, useEffect} from 'react';
import TweetInput from "./TweetInput/TweetInput";
import {Button} from "react-bootstrap";
import {BsUpload} from "react-icons/bs";
import {FaArrowRight} from "react-icons/fa6";
import {TwitterSteps, useProgressionHandling} from "../../../Twitter/Twitter";
import {useTweetData} from "../../../../hooks/twitterDataHooks/useTweetData";

interface FetchTweetProps extends HTMLAttributes<HTMLDivElement> {

}

/**
 * This component is the step of inputting the link to the respective tweet. It also fetches the tweet data for this
 * link and reports it back to its parent via the callback setTweetData
 */
function FetchTweet({...props}: FetchTweetProps) {
    const [tweetData, setTweetData] = useTweetData()
    const {setStep, setCurProgression} = useProgressionHandling()

    useEffect(() => {
        setStep(TwitterSteps.FETCH_TWEET)
    }, [])

    const handleStartTweetQuery = () => {
        setCurProgression(50)
    }

    const handleEndTweetQuery = (successful: boolean) => {
        if (successful) {
            setCurProgression(100)
        } else {
            setCurProgression(0)
        }
    }

    const gotoNextStep = () => {
        setStep(TwitterSteps.SUBSUME)
    }

    return (
        <div {...props} className={'FetchTweet'}>
            <p>
                Gib zunächst den Tweet an, der auf Hatespeech geprüft werden soll.
                Kopiere dazu den Link zum Tweet, der über die <strong>Teilen</strong>-Funktion erreichbar ist:
            </p>
            <p>
                <BsUpload aria-label={'Post teilen'}/> &ensp; <FaArrowRight aria-label={'Pfeil rechts'}/> &ensp; <q>Link
                zum Tweet kopieren</q>
            </p>
            <TweetInput tweetData={tweetData}
                        setTweetData={setTweetData}
                        onStartFetchTweet={handleStartTweetQuery}
                        onEndFetchTweet={handleEndTweetQuery}
                        onSubmit={gotoNextStep}
            />
            <Button disabled={tweetData === undefined}
                    onClick={gotoNextStep}
                    className={'check-tweet-button'}
            >
                Weiter zur Einschätzung des Tweets durch ChatGPT
            </Button>
        </div>
    )
}

export default FetchTweet;