import "./LogicValuesButton.scss"
import React, {ReactElement} from 'react';
import {LogicValue} from "../../types/swishAPI";
import {Button, ButtonGroup, ButtonGroupProps} from "react-bootstrap";
import {FaCheck, FaQuestion, FaX} from "react-icons/fa6";

interface LogicValuesButtonProps extends ButtonGroupProps {
    value?: LogicValue
    onClickValue?: (clickedValue: LogicValue) => void,
    true?: ReactElement,
    unknown?: ReactElement,
    false?: ReactElement,
    clickable?: boolean
}

function LogicValuesButton({
                               value,
                               onClickValue,
                               clickable,
                               ...props
                           }: LogicValuesButtonProps) {
    const isTrue = value === 'true'
    const isUnknown = value === 'unknown' || value === 'nonsensical' || value === undefined
    const isFalse = value === 'false'
    const isClickable = clickable ?? false

    const onClick = (value: LogicValue) => {
        if (onClickValue !== undefined) {
            onClickValue(value)
        }
    }

    return (
        <div>
            <ButtonGroup {...props} className={'LogicValuesButton-buttons ' + (isClickable ? 'clickable' : 'static')}>
                <Button className={'button true'}
                        variant={isTrue ? 'success' : 'light'}
                        active={isTrue}
                        onClick={() => onClick("true")}
                        disabled={!isClickable && !isTrue}
                >
                    {
                        props.true ||
                        <FaCheck aria-label={'Ja'}/>
                    }
                </Button>
                <Button className={'button unknown'}
                        variant={isUnknown ? 'secondary' : 'light'}
                        active={isUnknown}
                        onClick={() => onClick("unknown")}
                        disabled={!isClickable && !isUnknown}
                >
                    {
                        props.unknown ||
                        <FaQuestion aria-label={'unbekannt'}/>
                    }
                </Button>
                <Button className={'button false'}
                        variant={isFalse ? 'danger' : 'light'}
                        active={isFalse}
                        onClick={() => onClick("false")}
                        disabled={!isClickable && !isFalse}
                >
                    {
                        props.false ||
                        <FaX aria-label={'Nein'}/>
                    }
                </Button>
            </ButtonGroup>
        </div>
    )
}

export default LogicValuesButton;