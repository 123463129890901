import './SingleTweetView.css'
import React from 'react';
import {Tweet} from "../../../../types/tweet";
import {Card, CardProps} from "react-bootstrap";

interface SingleTweetViewProps extends CardProps {
    tweet: Tweet
}

function SingleTweetView({tweet, ...props}: SingleTweetViewProps) {
    const className = 'SingleTweetView ' + (props.className ?? '')

    return (
        <Card {...props} className={className}>
            <Card.Header className={'SingleTweetView-Header'}>
                <h6>{tweet.full_name} ({tweet.username}):</h6>
                {
                    tweet.path !== undefined &&
                    <div>
                        <a href={`https://x.com${tweet.path}`}
                           target={'_blank'}
                           rel={'noreferrer'}
                        >
                            In X öffnen
                        </a>
                    </div>
                }
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    {tweet.content}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default SingleTweetView;