import {AuthProviderProps} from "react-oidc-context";
import {WebStorageStateStore} from "oidc-client-ts";

export const oidcConfig: AuthProviderProps = {
    authority: `${process.env.REACT_APP_KEYCLOAK_HOST}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}`,
    client_id: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
    redirect_uri: `${process.env.REACT_APP_KEYCLOAK_REDIRECT_URI}`,
    // adapted from https://github.com/authts/react-oidc-context/blob/f175dcba6ab09871b027d6a2f2224a17712b67c5/src/AuthProvider.tsx#L20-L30
    onSigninCallback: (): void => {
        let currentURL = new URL(window.location.href)
        // we must remove the payload from the URL. Otherwise, singinSilent will not work, as it uses the old payload
        currentURL.searchParams.delete('state')
        currentURL.searchParams.delete('session_state')
        currentURL.searchParams.delete('code')
        // NOTE: we keep all other search params, as they probably contain useful information that the user wants to keep
        window.history.replaceState(
            {},
            "",
            currentURL.href
        )
    },
    userStore: new WebStorageStateStore({store: window.localStorage}),
};