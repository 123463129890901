import "./SimplePagination.scss"
import React, {HTMLAttributes} from 'react';
import {Button} from "react-bootstrap";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa6";

interface SimplePaginationProps extends HTMLAttributes<HTMLDivElement> {
    curPage: number,
    numPages: number,
    gotoPage: (newPage: number) => void
}

/**
 * This is a simple pagination component.
 * It displays the page numbers and allows clicking them.
 * The logic is handled via a callback function that should update the page number in its parent component.
 *
 * NOTE: internally the pages are counted starting at 0, but visualized starting at 1!
 *
 * @param curPage the current page
 * @param numPages the maximum number of pages,
 * @param gotoPage a callback function that updates the current page number
 * @param props directly passed to the enclosing div component
 * @constructor
 */
function SimplePagination({curPage, numPages, gotoPage, ...props}: SimplePaginationProps) {
    const canNavigateLeft = curPage > 0;
    const canNavigateRight = curPage < numPages - 1;

    const navigateLeft = () => gotoPage(curPage - 1)
    const navigateRight = () => gotoPage(curPage + 1)

    return (
        <div {...props} className={'SimplePagination ' + props.className ?? ''}>
            <Button className={'pagination-button navigation-button left'}
                    variant={'link'}
                    disabled={!canNavigateLeft}
                    onClick={navigateLeft}
            >
                <FaAngleLeft aria-label={'Vorheriges Element'}/>
            </Button>
            {
                Array(numPages).fill(0).map((_, i) => {
                    return <Button variant={'outline-secondary'}
                                   onClick={() => gotoPage(i)}
                                   active={i === curPage}
                                   className={'pagination-button'}
                                   key={i}
                    >
                        {i === curPage && "Empfänger "}
                        {i + 1}
                    </Button>
                })
            }
            <Button className={'pagination-button navigation-button right'}
                    variant={'link'}
                    disabled={!canNavigateRight}
                    onClick={navigateRight}
            >
                <FaAngleRight aria-label={'Nächstes Element'}/>
            </Button>
        </div>
    )
}

export default SimplePagination;