import "./ViewFactDescription.scss"
import React, {HTMLAttributes} from 'react';
import {FactDescriptionInput} from "../../types/types";
import TweetView from "../Twitter/TweetView/TweetView";

export type ViewFactDescriptionTextOverwrite = {
    tweetHeading?: string,
    textHeading?: string
}

export interface FactDescriptionVisualizationProps extends HTMLAttributes<HTMLDivElement> {
    factDescription: FactDescriptionInput | null
    textOverwrites?: ViewFactDescriptionTextOverwrite
}

function ViewFactDescription({factDescription, textOverwrites, ...props}: FactDescriptionVisualizationProps) {
    const className = 'FactDescriptionVisualization ' + (props.className ?? '')

    if (factDescription === null) {
        return <div></div>
    }

    const tweetHeading = textOverwrites && textOverwrites.tweetHeading? textOverwrites.tweetHeading : "Aktueller Tweet:"
    const textHeading = textOverwrites && textOverwrites.textHeading? textOverwrites.textHeading : "Zu erfassender Inhalt:"

    switch (factDescription.type) {
        case "tweet":
            return <div {...props} className={className}>
                <h2>{tweetHeading}</h2>
                <TweetView tweetWithContext={factDescription.tweet}/>
            </div>
        case "text":
            return <div {...props} className={className}>
                <h2>{textHeading}</h2>
                <div className={'FactDescriptionVisualization-text'}>
                    <pre>
                    {factDescription.text}
                    </pre>
                </div>
            </div>
    }
    // unreachable
}

export default ViewFactDescription;