import "./GeneralWorkflowDiagram.css";
import React, { HTMLAttributes } from "react";
import Arrows from "../util/Arrows/Arrows";
import {
  FaBook,
  FaCircleCheck,
  FaRobot,
  FaUpRightFromSquare,
} from "react-icons/fa6";
import { useExpertMode } from "../../../hooks/settingsCTXHooks/useExpertMode";
import { useDimensions } from "../../../hooks/useDimensions";

interface GeneralWorkflowDiagramProps extends HTMLAttributes<HTMLDivElement> {}

function GeneralWorkflowDiagram({ ...props }: GeneralWorkflowDiagramProps) {
  const className = "GeneralWorkflowDiagram " + (props.className ?? "");
  const [expertMode] = useExpertMode();
  const dimensions = useDimensions();
  const MOBILE_THRESHOLD_WIDTH = 800;
  const MAX_NUM_ARROWS = 8;

  const numArrows = Math.min(
    Math.max(3, Math.floor(dimensions.width / 500)),
    MAX_NUM_ARROWS
  );

  const inputText = expertMode ? "URL oder Freitext" : "URL";

  // ========================= Definition of the shared Elements ============================
  const InputIcon = (
    <div className={"input icon"}>
      <FaUpRightFromSquare aria-label={"Upload Icon"} />
    </div>
  );
  const InputDescription = (
    <span className={"input description"}>Äußerung eingeben</span>
  );

  const AnalyzeIcon = (
    <div className={"analyze icon"}>
      <FaRobot />+<FaBook />
    </div>
  );
  const AnalyzeDescription = (
    <span className={"analyze description"}>
      Analyse durch KI + Wissensbasis
    </span>
  );

  const ResultIcon = (
    <div className={"result icon"}>
      <FaCircleCheck aria-label={"Haken"} />
    </div>
  );
  const ResultDescription = (
    <div className={"result description"}>Ergebnis: isthishatespeech?</div>
  );

  // ======================= Mobile Case ============================
  if (dimensions.width < MOBILE_THRESHOLD_WIDTH) {
    return (
      <div {...props} className={className}>
        {InputIcon}
        {InputDescription}

        <Arrows
          className={"arrow input-to-analyze"}
          moving={false}
          direction={"down"}
          numArrows={numArrows}
        />

        {AnalyzeIcon}
        {AnalyzeDescription}

        <Arrows
          className={"arrow analyze-to-result"}
          moving={false}
          direction={"down"}
          numArrows={numArrows}
        />

        {ResultIcon}
        {ResultDescription}
      </div>
    );
  }

  return (
    <div {...props} className={className}>
      <div className={"input single-step"}>
        {InputIcon}
        {InputDescription}
      </div>

      <Arrows moving={false} direction={"right"} numArrows={numArrows} />

      <div className={"analyze single-step"}>
        {AnalyzeIcon}
        {AnalyzeDescription}
      </div>

      <Arrows moving={false} direction={"right"} numArrows={numArrows} />

      <div className={"results single-step"}>
        {ResultIcon}
        {ResultDescription}
      </div>
    </div>
  );
}

export default GeneralWorkflowDiagram;
