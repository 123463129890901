import "./YesNoQuestion.scss"
import React, {useContext, useState} from 'react';
import {ButtonGroup} from "react-bootstrap";
import {JuriCoreStatus} from "../../../../../types/juricore";
import LoadingButton from "../../../../LoadingButton/LoadingButton";
import {IntersectionalBotCTX} from "../../../../../context/IntersectionalBotCTX";
import {QuestionProps} from "../Question";
import {MessageType} from "../../../IntersectionalBot";
import JunBotMarkdown from "../../../../JunBotMarkdown/JunBotMarkdown";
import ExpandableText from "../../../../ExpandableText/ExpandableText";

interface YesNoQuestionProps extends QuestionProps {
}

function YesNoQuestion({question, onCreateAnswer, ...props}: YesNoQuestionProps) {
    const className = 'YesNoQuestion ' + (props.className ?? '')
    const intersectionalBot = useContext(IntersectionalBotCTX)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isAnswered, setIsAnswered] = useState<boolean>(false)

    const updateStatus = (newStatus: JuriCoreStatus) => {
        setIsLoading(true)
        setIsAnswered(true)
        intersectionalBot.setQuestionStatus(question._id, newStatus).then(() => {
            setIsLoading(false)
        }).catch((error) => {
            console.error(`Error updating the question with id '${question._id}' to status '${newStatus}'`)
            console.error(error)
            // todo: Visualize error to use with notification to try again later
            setIsLoading(false)
            setIsAnswered(false)
        })
    }

    const handleCreateAnswer = (answerContent: string) => {
        if (!onCreateAnswer) {
            console.warn('no onCreateAnswer method provided => no answer will be visible')
            return
        }
        // generate answer
        onCreateAnswer({
            msgType: MessageType.Answer,
            id: question._id + '-answer-' + Date.now(),
            answer: <JunBotMarkdown>
                {answerContent}
            </JunBotMarkdown>
        })
    }

    const handleClickYes = () => {
        updateStatus(1)
        handleCreateAnswer(question.values.textAnswerYes ?? 'Ja')
    }

    const handleClickNo = () => {
        updateStatus(-1)
        handleCreateAnswer(question.values.textAnswerNo ?? 'Nein')
    }


    return <div {...props} className={className}>
        <JunBotMarkdown className={'YesNoQuestion-question'}>
            {question.values.question}
        </JunBotMarkdown>
        {
            question.values.description && <ExpandableText titleElement={(isExpanded) => {
                if (isExpanded) return "Weniger anzeigen"
                return "Mehr anzeigen"
            }}>
                <JunBotMarkdown>
                    {question.values.description}
                </JunBotMarkdown>
            </ExpandableText>
        }
        {
            !isAnswered && <div className={'YesNoQuestion-buttons'}>
                <ButtonGroup>
                    <LoadingButton isLoading={isLoading} variant={'success'} onClick={handleClickYes}>
                        Ja
                    </LoadingButton>
                    <LoadingButton isLoading={isLoading} variant={'danger'} onClick={handleClickNo}>
                        Nein
                    </LoadingButton>
                </ButtonGroup>
            </div>
        }
    </div>
}

export default YesNoQuestion;