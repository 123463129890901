import {useEffect, useState} from "react";

export type Dimensions = {
    width: number,
    height: number
}

function getWindowDimensions(): Dimensions {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export const useDimensions = (): Dimensions => {
    const [dimensions, setDimensions] = useState<Dimensions>(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
            setDimensions(getWindowDimensions)
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return dimensions
}