import "./ViewParagraphResult.scss";
import React, { HTMLAttributes } from "react";
import VIEW_PARAGRAPH_RESULT_TEXTS, {
  SingleResultTexts,
} from "./VIEW_PARAGRAPH_RESULT_TEXTS";
import ExpandableText from "../ExpandableText/ExpandableText";
import { useEvaluationResult } from "../../hooks/twitterDataHooks/useEvaluationResult";
import { LogicValue } from "../../types/swishAPI";
import ExplanationList from "../customLists/ExplanationList/ExplanationList";
import JunBotMarkdown from "../JunBotMarkdown/JunBotMarkdown";

export interface ViewParagraphResultProps
  extends HTMLAttributes<HTMLDivElement> {
  paragraph: "stgb185" | "stgb186" | "stgb187";
}

function ViewParagraphResult({
  paragraph,
  ...props
}: ViewParagraphResultProps) {
  const className = "ViewParagraphResult " + (props.className ?? "");
  const [fullResult] = useEvaluationResult();

  let texts: SingleResultTexts;
  let paragraphResult: LogicValue;
  switch (paragraph) {
    case "stgb185":
      texts = VIEW_PARAGRAPH_RESULT_TEXTS.stgb185;
      paragraphResult = fullResult?.stgb185.value ?? "unknown";
      break;
    case "stgb186":
      texts = VIEW_PARAGRAPH_RESULT_TEXTS.stgb186;
      paragraphResult = fullResult?.stgb186.value ?? "unknown";
      break;
    case "stgb187":
      texts = VIEW_PARAGRAPH_RESULT_TEXTS.stgb187;
      paragraphResult = fullResult?.stgb187.value ?? "unknown";
      break;
  }

  return (
    <div {...props} className={className + ` ${paragraphResult}`}>
      <h3>{texts.heading}</h3>
      <div className={"ViewParagraphResult-content"}>
        <div
          className={
            "ViewParagraphResult-colorbar result-background " + paragraphResult
          }
          role={"presentation"}
        />
        <div className={"ViewParagraphResult-result"}>
          <JunBotMarkdown
            className={`ViewParagraphResult-result ${paragraphResult}`}
          >
            {texts.result.get(paragraphResult)}
          </JunBotMarkdown>
          {texts.additionalInformation.map(({ title, description }, index) => (
            <ExpandableText titleElement={title} key={index}>
              <ExplanationList
                explanations={[texts.description, description]}
              />
            </ExpandableText>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ViewParagraphResult;
