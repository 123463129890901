import "./ProfileMenu.scss"
import React, {HTMLAttributes} from 'react';
import {Button} from "react-bootstrap";
import {useAuth} from "react-oidc-context";
import {useExpertMode} from "../../../hooks/settingsCTXHooks/useExpertMode";

interface ProfileMenuProps extends HTMLAttributes<HTMLDivElement> {

}

function ProfileMenu({...props}: ProfileMenuProps) {
    const auth = useAuth()
    const [_, setExpertMode] = useExpertMode()
    const className = 'ProfileMenu ' + (props.className ?? '')

    const handleLogout = () => {
        auth.signoutSilent({post_logout_redirect_uri: 'https://isthishatespeech.de'})
            .then(() => {
                console.log('successfully logged out')
                setExpertMode(false)
            })
    }

    return (
        <div {...props} className={className}>
            <Button variant={'text-link'}
                    className={'button-no-color inline-button'}
                    onClick={handleLogout}
            >
                Abmelden
            </Button>
        </div>
    )
}

export default ProfileMenu;