import {SwishAPIResponseJSON} from "../../types/swishAPI";
import {useContext} from "react";
import {HatespeechDataCTX} from "../../context/HatespeechDataCTX";

export type useEvaluationResultType = [
    SwishAPIResponseJSON | null,
    (newEvaluationResult: SwishAPIResponseJSON) => void
]

/**
 * Helper-Hook to extract the evaluation values from the twitter context.
 *
 * NOTE: you can use it exactly as the useState hook.
 *
 * If evaluation is null, no evaluation was queried yet.
 */
export const useEvaluationResult = (): useEvaluationResultType => {
    const twitterCTX = useContext(HatespeechDataCTX)

    return [
        twitterCTX.evaluate.result,
        twitterCTX.evaluate.setResult
    ]
}