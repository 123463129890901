import {useFactDescription} from "./useFactDescription";

export type useTextInputType = [string | null, (newTextInput: string | null) => void]

export const useTextInput = (): useTextInputType => {
    const [input, setInput] = useFactDescription()

    const setInputText = (text: string | null) => {
        if (text === null) {
            setInput(null)
            return
        }

        setInput({
            type: 'text',
            text: text
        })
    }

    if (input?.type !== 'text') {
        return [null, setInputText]
    }

    return [input.text, setInputText]
}