import "./TextualDescriptionInput.scss";
import React, { FormEventHandler, HTMLAttributes } from "react";
import { Button, Form } from "react-bootstrap";
import { TwitterSteps, useProgressionHandling } from "../../../Twitter/Twitter";
import { useTextInput } from "../../../../hooks/twitterDataHooks/useTextInput";
import ExpandableText from "../../../ExpandableText/ExpandableText";
import ExampleList from "../../../customLists/ExampleList/ExampleList";

export const MAX_INPUT_LENGTH = 800;

interface TextualDescriptionProps extends HTMLAttributes<HTMLDivElement> {}

/**
 * This component provides the input form for the 'text' input modality.
 *
 * @param props
 * @constructor
 */
function TextualDescriptionInput({ ...props }: TextualDescriptionProps) {
  const [textInput, setTextInput] = useTextInput();
  const { setStep } = useProgressionHandling();
  const className = "TextualDescription " + (props.className ?? "");

  const handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    event.preventDefault();
    let newTextInput = event.currentTarget.value;
    // cap text length
    if (newTextInput.length > MAX_INPUT_LENGTH) {
      newTextInput = newTextInput.substring(0, MAX_INPUT_LENGTH);
    }
    setTextInput(newTextInput);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setStep(TwitterSteps.SUBSUME);
  };

  return (
    <div {...props} className={className}>
      <h1>Erfassung des Sachverhalts</h1>
      <p>
        <strong>Hinweis:</strong> Die von Dir bereitgestellten Daten werden zur
        Verarbeitung, in Form der Sachverhaltserfassung, an ChatGPT
        weitergeleitet. Wir bitten Dich insofern, <strong>keine</strong>{" "}
        personenbezogenen Daten, insbesondere die Dritter, einzugeben. Benutze
        gerne Pseudonyme. Mit dem "Weiter zur Sachverhaltsanalyse durch
        ChatGPT"-Button stimmst Du dem Hinweis zu.
      </p>
      <ExpandableText
        titleElement={<span>Was sind personenbezogene Daten?</span>}
      >
        <p>
          Personenbezogene Daten sind Informationen, durch die eine Person
          bestimmt werden kann.
        </p>
        <ExampleList
          type={"positive"}
          examples={["Name und Vorname", "Wohnadresse", "E-Mail-Adresse"]}
        />
        <p>Pseudonyme können bspw. Anfangsbuchstaben sein.</p>
      </ExpandableText>
      <Form className={"TextualDescription-Form"} onSubmit={handleSubmit}>
        <Form.Control
          as={"textarea"}
          id={"inputFactDescription"}
          rows={10}
          value={textInput ?? ""}
          onChange={handleInputChange}
          maxLength={800}
          placeholder={"Gib die Äußerung inklusive Kontext ein..."}
        />
        <Form.Text
          id={"inputFactDescription"}
          muted
          className={"TextualDescription-character-limit"}
        >
          {textInput ? textInput.length : 0} / {MAX_INPUT_LENGTH} Zeichen
        </Form.Text>
        <p>
          Gib zunächst die Äußerung ein, die auf Hatespeech geprüft werden soll.
          Bitte gib den Kontext der Äußerung an – umso mehr Kontext wir
          erhalten, desto genauer können wir den Sachverhalt auswerten.
        </p>
        <Button
          type={"submit"}
          disabled={textInput === ""}
          className={"TextualDescription-submit"}
        >
          Weiter zur Sachverhaltsanalyse durch ChatGPT
        </Button>
      </Form>
    </div>
  );
}

export default TextualDescriptionInput;
