import "./ExampleList.scss"
import React, {HTMLAttributes, ReactElement} from 'react';
import {FaThumbsDown, FaThumbsUp} from "react-icons/fa6";
import IconList from "../IconList/IconList";

interface ExampleListProps extends HTMLAttributes<HTMLUListElement> {
    type: 'positive' | 'negative'
    examples: string[]
}

function ExampleList({type, examples, ...props}: ExampleListProps) {
    const className = 'ExampleList ' + (props.className ?? '')

    let icon: ReactElement;

    switch (type) {
        case "positive":
            icon = <FaThumbsUp aria-label={'Daumen hoch'} className={'ExampleList-positive'}/>
            break;
        case "negative":
            icon = <FaThumbsDown aria-label={'Daumen runter'} className={'ExampleList-negative'}/>
            break;
    }

    return <IconList className={className}
                     bullet={icon}
                     elements={examples.map(example => <span>
                         {example}
                     </span>)}
    />
}

export default ExampleList;