import "./FAQ.css";
import React, { HTMLAttributes } from "react";
import ExpandableText from "../../ExpandableText/ExpandableText";

interface FAQProps extends HTMLAttributes<HTMLDivElement> {}

function FAQ({ ...props }: FAQProps) {
  const className = "FAQ " + (props.className ?? "");

  return (
    <div {...props} className={className}>
      <ExpandableText
        titleElement={
          <span>
            Juriskop Hatespeech-Bot – <strong>Is this hatespeech?</strong>
          </span>
        }
      >
        <ul>
          <li>
            Ist das schon eine Beleidigung oder bloß unhöflich? Wann liegt üble
            Nachrede vor? Was kann ich tun, wenn ich verleumdet werde? Mit nur
            wenigen Klicks schätzt unser Hatespeech-Bot ein, ob es sich bei
            Online-Inhalten um Hatespeech handeln könnte.
          </li>
          <li>
            Mit juristischem Wissen verknüpfte Large Language Models (sog.
            “LLM”) helfen uns dabei, Deinen Fall zu prüfen.
          </li>
          <li>
            Kannst Du es besser als die Künstliche Intelligenz? In unserem
            Prototypen überlassen wir Dir die Wahl, ob du die Ersteinschätzung
            des LLM teilst. Wenn nicht, kannst Du einzelne juristische
            Einschätzungen schnell anpassen. Dabei stehen Dir die juristischen
            Hinweise unseres Expert*innen-Teams in einfach verständlicher
            Sprache zur Verfügung.
          </li>
        </ul>
      </ExpandableText>

      <ExpandableText
        titleElement={
          <span>
            Hatespeech – <strong>Bloße Unhöflichkeit</strong> oder{" "}
            <strong>rechtsverletzender Inhalt</strong>?
          </span>
        }
      >
        <ul>
          <li>
            Hatespeech:
            <ul>
              <li>digitale, gruppenbezogene Menschenfeindlichkeit</li>
              <li>
                bezieht sich auf inhärente Eigenschaften, also solche, die sich
                nicht einfach “ablegen” lassen. Beispielsweise Ethnie, Gender
                und Religion.
              </li>
            </ul>
          </li>
          <li>
            Steht häufig im Kontext von Rassismus, Fremdenfeindlichkeit,
            Sexismus, Antisemitismus, Misogynie oder Ableismus.
          </li>
          <li>
            Die Erscheinungsformen von Hatespeech sind vielfältig und reichen
            von beleidigenden Äußerungen, über Aufrufen zu Gewalt, bis hin zu
            Drohungen.
          </li>
          <li>
            Du bist nicht allein – 75 % der Nutzenden von sozialen Plattformen
            sind bereits Hasskommentaren begegnet.
          </li>
          <li>
            Das Recht steht auf Deiner Seite: Gegen rechtsverletzende Inhalte
            kannst Du zivil- und strafrechtlich vorgehen.
          </li>
        </ul>
      </ExpandableText>

      <ExpandableText
        titleElement={
          <span>
            <strong>Juriskop</strong> – Das Legal-Tech-Forschungsprojekt der
            Kanzlei Jun Rechtsanwälte und der Universität Würzburg
          </span>
        }
      >
        <ul>
          <li>
            Der Hatespeech-Bot ist Teil des staatlich geförderten,
            interdisziplinären Forschungsprojekts{" "}
            <a href={"https://juriskop.de"} target={"_blank"} rel="noreferrer">
              Juriskop
            </a>{" "}
            der Kanzlei{" "}
            <a href={"https://junit.de"} target={"_blank"} rel="noreferrer">
              Jun Rechtsanwälte
            </a>{" "}
            in Kooperation mit der{" "}
            <a
              href={"https://www.informatik.uni-wuerzburg.de/is/"}
              target={"_blank"}
              rel="noreferrer"
            >
              Universität Würzburg
            </a>
            .
          </li>
          <li>
            Wir entwickeln eine niederschwellige, für jeden Betroffenen
            zugängliche Einzelfallberatung, mithilfe von Chatbots.
          </li>
          <li>
            Dazu braucht unser Hatespeech-Bot Übung und Du kannst uns dabei
            helfen! Mit Deinem Chat bringst Du uns unserem Ziel näher.
          </li>
          <li>
            Haben wir Dein Interesse geweckt? Dann teste jetzt auch unsere
            Prototypen zu den Themen{" "}
            <a
              href={"https://juriskop.de/unsere-prototypen/erbrechts-bot/"}
              target={"_blank"}
              rel="noreferrer"
            >
              Erbrecht
            </a>{" "}
            und dem{" "}
            <a
              href={
                "https://bots.kanzlei-jun.de/junbots/Help_for_Ukrainians_v2"
              }
              target={"_blank"}
              rel={"noreferrer"}
            >
              Aufenthaltsrecht ukrainischer Geflüchteter
            </a>
            !
          </li>
        </ul>
      </ExpandableText>
    </div>
  );
}

export default FAQ;
