import "./ProfileIcon.scss"
import React, {HTMLAttributes, useRef, useState} from 'react';
import {useAuth} from "react-oidc-context";
import LoginButton from "../LoginButton/LoginButton";
import {Overlay} from "react-bootstrap";
import {FaAngleDown, FaAngleUp, FaRegUser} from "react-icons/fa6";
import ProfileMenu from "../ProfileMenu/ProfileMenu";

interface ProfileIconProps extends HTMLAttributes<HTMLDivElement> {
    onLogin?: () => void
}

function ProfileIcon({onLogin, ...props}: ProfileIconProps) {
    const auth = useAuth();
    const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false)
    const profileIconRef = useRef(null);

    const className = 'ProfileIcon ' + (props.className ?? '')

    if (!auth.isAuthenticated) {
        return <LoginButton onLogin={onLogin}/>
    }

    return (
        <div {...props} className={className}>
            <div role={'button'}
                 ref={profileIconRef}
                 onClick={() => setShowProfileMenu(!showProfileMenu)}
            >
                <div className={'ProfileIcon-title'}>
                    <FaRegUser aria-label={'Profilbild'}/>
                    <span>{auth.user?.profile.preferred_username}</span>
                    {showProfileMenu ? <FaAngleUp aria-label={'einklappen'}/> :
                        <FaAngleDown aria-label={'ausklappen'}/>}
                </div>
            </div>
            <Overlay target={profileIconRef.current}
                     show={showProfileMenu}
                     placement={'bottom'}
                     rootClose={true} // close overlay on click outside
                     onHide={() => setShowProfileMenu(false)}
            >
                <div>
                    <ProfileMenu/>
                </div>
            </Overlay>
        </div>
    )
}

export default ProfileIcon;