import "./InfoCard.scss"
import React, {HTMLAttributes, ReactElement} from 'react';
import {FaTriangleExclamation} from "react-icons/fa6";

interface InfoCardProps extends HTMLAttributes<HTMLDivElement> {
    children: string | ReactElement | ReactElement[]
}

/**
 * This component is a card component that represents any kind of hint/information.
 * It renders all children inside a specific area next to an information icon.
 *
 * @param props are directly passed to the enclosing div of the child content.
 * @constructor
 */
function InfoCard({...props}: InfoCardProps) {

    return <div className={'InfoCard'}>
        <div className={'InfoCard-icon'}>
            <FaTriangleExclamation aria-label={'Hinweis'}/>
        </div>
        <div className={'vertical-separator'}/>
        <div {...props}>
            {props.children}
        </div>
    </div>
}

export default InfoCard;