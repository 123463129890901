import "./Answer.scss"
import React, {HTMLAttributes} from 'react';
import {AnswerMessage} from "../../IntersectionalBot";

interface AnswerProps extends HTMLAttributes<HTMLDivElement> {
    answer: AnswerMessage
}

function Answer({answer, ...props}: AnswerProps) {
    const className = 'Answer Message-user ' + (props.className ?? '')
    return (
        <div {...props} className={className}>
            {answer.answer}
        </div>
    )
}

export default Answer;