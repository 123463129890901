import "./Arrows.scss"
import React, {HTMLAttributes, JSXElementConstructor, useEffect, useState} from 'react';
import {FaAngleDown, FaAngleRight} from "react-icons/fa6";


interface ArrowsProps extends HTMLAttributes<HTMLDivElement> {
    moving: boolean,
    direction: 'down' | 'right',
    numArrows?: number
}

/**
 * This component draws an arrow by displaying multiple '>' icons.
 * You can specify the number of icons which translates directly to the length of the arrows.
 *
 * The arrows can be animated by setting moving to true.
 * Then the arrows are moving in the direction they are pointing to.
 *
 * @param moving whether the arrows should be animated, i.e., moving into pointing direction
 * @param direction the direction of the arrows
 * @param numArrows the number of displayed '>' icons. Directly translates to the length of the arrow
 * @param props other properties that are directly passed to the enclosing div
 * @constructor
 */
function Arrows({moving, direction, numArrows, ...props}: ArrowsProps) {
    const [totalNumArrows, setTotalNumArrows] = useState<number>((numArrows ?? 4) + 1)
    const ARROW_SIZE = 16 // size of each arrow in px

    useEffect(() => setTotalNumArrows((numArrows ?? 4) + 1), [numArrows])

    let ArrowIcon: JSXElementConstructor<any>
    let containerStyle: Record<string, any>
    switch (direction) {
        case "down":
            ArrowIcon = (key) => <FaAngleDown aria-label={'Pfeil nach unten'} key={key}/>
            // we restrict the height of the container to show one less arrow than we add => animation is possible
            containerStyle = {
                height: (totalNumArrows - 1) * ARROW_SIZE
            }
            break
        case "right":
            ArrowIcon = (key) => <FaAngleRight aria-label={'Pfeil nach rechts'} key={key}/>
            // same as in case 'down' just for the width
            containerStyle = {
                width: (totalNumArrows - 1) * ARROW_SIZE
            }
            break
    }

    return <div {...props} className={'Arrows ' + props.className ?? ''}
                style={containerStyle}>
        <div className={`Arrows-arrows ${direction} ` + (moving ? 'animated' : 'static')}>
            {
                Array(totalNumArrows).fill(0).map((_, i) => <ArrowIcon key={i}/>)
            }
        </div>
    </div>
}

export default Arrows;