import "./SocialButtons.scss"
import React, {HTMLAttributes} from 'react';
import {FaGithub, FaLinkedin, FaTwitter} from "react-icons/fa6";

interface SocialButtonsProps extends HTMLAttributes<HTMLDivElement> {
}

/**
 * This component renders all social links of the Juriskop Team as clickable buttons.
 * It tries to copy the look of the social buttons on the juriskop.de website.
 *
 * Following social links are supported:
 *  - LinkedIn
 *  - Twitter
 *  - GitHub
 *
 * @param props
 * @constructor
 */
function SocialButtons({...props}: SocialButtonsProps) {
    const className = 'SocialButtons ' + (props.className ?? '')

    return <div {...props} className={className}>
        {/********************* LinkedIn *************************************/}
        <a href={'https://www.linkedin.com/showcase/juriskop'}
           className={'SocialButtons-button SocialButtons-LinkedIn'}
           target={'_blank'}
           role={'button'}
        >
            <FaLinkedin aria-label={'LinkedIn'} className={'SocialButtons-icon'}/>
        </a>
        {/********************* Twitter **************************************/}
        <a href={'https://twitter.com/juriskop'}
           className={'SocialButtons-button SocialButtons-Twitter'}
           target={'_blank'}
        >
            <FaTwitter aria-label={'Twitter'} className={'SocialButtons-icon'}/>
        </a>
        {/********************** GitHub **************************************/}
        <a href={'https://github.com/Juriskop'}
           className={'SocialButtons-button SocialButtons-GitHub'}
           target={'_blank'}
        >
            <FaGithub aria-label={'GitHub'} className={'SocialButtons-icon'}/>
        </a>
    </div>
}

export default SocialButtons;