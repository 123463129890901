import "./ChatGPTResult.scss";
import React, { HTMLAttributes, useEffect, useState } from "react";
import { ChatGPTResponsePropertyDescription } from "../../../types/swishAPI";
import { useAPIConnector } from "../../../hooks/useAPIConnector";
import ViewChatGPTResult from "./ViewChatGPTResult/ViewChatGPTResult";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";

interface ChatGPTResultProps extends HTMLAttributes<HTMLDivElement> {
  editable?: boolean;
}

/**
 * This component visualizes a ChatGPT result and allows the switching between normal and advanced mode.
 *
 * @param editable whether the properties should be editable (default: false)
 * @param props
 * @constructor
 */
function ChatGPTResult({ editable, ...props }: ChatGPTResultProps) {
  const [propertyDescription, setPropertyDescription] =
    useState<ChatGPTResponsePropertyDescription>({});
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const api = useAPIConnector();
  const isEditable = editable ?? false;

  useEffect(() => {
    api.getDescription().then(setPropertyDescription);
  }, [api]);

  return (
    <div {...props} className={"ChatGPTResult"}>
      <ViewChatGPTResult
        showAdvanced={showAdvanced}
        propertyDescription={{
          displayName: "", // do not show heading
          description: undefined,
          type: "Group",
          children: propertyDescription,
          advanced: false,
        }}
        editable={isEditable}
      />
      {/* <ToggleButtonGroup
        type={"radio"}
        name={"display-mode"}
        value={showAdvanced ? "advanced" : "simple"}
        onChange={(value) => setShowAdvanced(value === "advanced")}
        className={"toggle-advanced-view"}
      >
        <ToggleButton
          value={"simple"}
          type={"radio"}
          id={"display-mode-simple"}
          variant={showAdvanced ? "outline-secondary" : "secondary"}
        >
          Normale Ansicht
        </ToggleButton>
        <ToggleButton
          value={"advanced"}
          type={"radio"}
          id={"display-mode-advanced"}
          variant={showAdvanced ? "secondary" : "outline-secondary"}
        >
          Erweiterte Ansicht
        </ToggleButton>
      </ToggleButtonGroup> */}
    </div>
  );
}

export default ChatGPTResult;
