import "./ExpandButton.scss"
import React from 'react';
import {FaAngleDown, FaAngleUp} from "react-icons/fa6";
import {Button, ButtonProps} from "react-bootstrap";

interface ExpandButtonProps extends ButtonProps {
    expanded: boolean,
    setExpanded: (newExpanded: boolean) => void
}

/**
 * This component is a button for switching between an expanded and collapsed state.
 *
 * It uses a Bootstrap button.
 *
 * @param expanded the current state
 * @param setExpanded callback function to update the expanded state in the parent component
 * @param props other props that are directly passed to the bootstrap button.
 * @constructor
 */
function ExpandButton({expanded, setExpanded, ...props}: ExpandButtonProps) {
    if (expanded) {
        return <Button className={'button-no-color inline-button'}
                       onClick={() => setExpanded(false)}
                       {...props}
        >
            <FaAngleUp aria-label={'einklappen'}/>
        </Button>
    }
    return <Button className={'button-no-color inline-button'}
                   onClick={() => setExpanded(true)}
                   {...props}
    >
        <FaAngleDown aria-label={'ausklappen'}/>
    </Button>
}

export default ExpandButton;