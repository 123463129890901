import {createContext} from "react";

export interface APIContextType {
    uuid: string,
    setUUID: (uuid: string) => void
}

export const APIContext = createContext<APIContextType>({
    uuid: '',
    setUUID: () => {}
})