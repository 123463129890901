import "./TweetView.scss"
import React, {HTMLAttributes} from 'react';
import {TweetWithContext} from "../../../types/tweet";
import SingleTweetView from "./SingleTweetView/SingleTweetView";
import LoadingTweet from "../../LoadingAnimations/LoadingTweet/LoadingTweet";

interface TweetViewProps extends HTMLAttributes<HTMLDivElement> {
    isLoading?: boolean,
    tweetWithContext: TweetWithContext | null
}

function TweetView({tweetWithContext, isLoading, ...props}: TweetViewProps) {
    if (isLoading) {
        return <LoadingTweet/>
    }

    if (tweetWithContext === null) {
        return <></>
    }

    return (
        <div {...props}>
            {tweetWithContext.context.length > 0 &&
                <div className={'context-tweet-container'}>
                    {
                        tweetWithContext.context.reverse().map((contextTweet, index) =>
                            <SingleTweetView tweet={contextTweet}
                                             className={'tweet content-tweet'}
                                             key={index}
                            />
                        )
                    }
                </div>
            }
            <SingleTweetView tweet={tweetWithContext.mainTweet} className={'tweet main-tweet'}/>
        </div>
    )
}

export default TweetView;