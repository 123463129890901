import "./MarkQualityInput.css"
import React, {HTMLAttributes, useState} from 'react';
import {ButtonGroup} from "react-bootstrap";
import {FaChildReaching} from "react-icons/fa6";
import InfoCard from "../cards/InfoCard/InfoCard";
import {useAPIConnector} from "../../hooks/useAPIConnector";
import LoadingButton from "../LoadingButton/LoadingButton";

enum QualityStatus {
    UNSELECTED,
    IS_QUALITY_INPUT,
    JUST_PLAYING,
    ERROR
}

interface MarkQualityInputProps extends HTMLAttributes<HTMLDivElement> {
}

/**
 * This component is an interactive component that lets the user verify the quality of the input data.
 * This means, that his/her data can be used in further training/evaluation.
 *
 * It contains two buttons to set the quality attribute in the backend to true/false.
 * After pressing one of the buttons, the selected version is shown, but no editing is allowed afterwards.
 *
 * @param props
 * @constructor
 */
function MarkQualityInput({...props}: MarkQualityInputProps) {
    const className = 'MarkQualityInput ' + (props.className ?? '')
    const api = useAPIConnector()
    const [status, setStatus] = useState<QualityStatus>(QualityStatus.UNSELECTED)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleClickFactory = (isQualityInput: boolean) => () => {
        setIsLoading(true)
        setStatus(isQualityInput ? QualityStatus.IS_QUALITY_INPUT : QualityStatus.JUST_PLAYING)
        api.setQualityInput(isQualityInput).then(() => {
            setIsLoading(false)
        }).catch(error => {
            console.error(error)
            setStatus(QualityStatus.ERROR)
            setIsLoading(false)
        })
    }

    if (!isLoading && status === QualityStatus.IS_QUALITY_INPUT) {
        return <InfoCard {...props} className={className}>
            Eingegebene Daten werden für ein weiteres Training/Evaluation verwendet.
        </InfoCard>
    }

    if (!isLoading && status === QualityStatus.JUST_PLAYING) {
        return <></>
    }

    return <InfoCard {...props} className={className}>
            <span>
        Sind die Eingaben (Genutztes Beispiel, Überarbeitung Einschätzung ChatGPT) von der Qualität,
        dass sie für ein Training oder eine Evaluation genutzt werden können?
            </span>
        <div>
            <ButtonGroup>
                <LoadingButton variant={'outline-yes'}
                               className={'MarkQualityInput-yes'}
                               onClick={handleClickFactory(true)}
                               isLoading={isLoading && (status === QualityStatus.IS_QUALITY_INPUT)}
                >
                    Ja, Daten können verwendet werden
                </LoadingButton>
                <LoadingButton variant={'outline-no'}
                               className={'MarkQualityInput-no'}
                               onClick={handleClickFactory(false)}
                               isLoading={isLoading && (status === QualityStatus.JUST_PLAYING)}
                >
                    <div className={'MarkQualityInput-no'}>
                        <span>
                            Nein, ich wollte nur spielen
                        </span>
                        <FaChildReaching/>
                    </div>
                </LoadingButton>
            </ButtonGroup>
        </div>
    </InfoCard>
}

export default MarkQualityInput;