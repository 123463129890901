import "./TwitterPipeline.scss"
import React, {HTMLAttributes} from 'react';
import {FaBook, FaCircleCheck} from "react-icons/fa6";
import {Dimensions, useDimensions} from "../../../hooks/useDimensions";
import {FactDescriptionInput} from "../../../types/types";
import ViewFactDescription, {ViewFactDescriptionTextOverwrite} from "../../ViewFactDescription/ViewFactDescription";
import Arrows from "../../visualizations/util/Arrows/Arrows";
import BotIcon from "../../icons/BotIcon/BotIcon";


export type TwitterPipelineSteps = 'ChatGPT' | 'logic' | 'result'

export interface TwitterPipelineProps extends HTMLAttributes<HTMLDivElement> {
    factDescription: FactDescriptionInput | null,
    isLoading: boolean,
    curStep: TwitterPipelineSteps,
    viewFactDescriptionTextOverwrites?: ViewFactDescriptionTextOverwrite
}

function TwitterPipeline({
                             factDescription,
                             isLoading,
                             curStep,
                             viewFactDescriptionTextOverwrites,
                             ...props
                         }: TwitterPipelineProps) {
    const windowDimensions = useDimensions()

    const getNumArrowsVertical = (curDimensions: Dimensions): number => {
        if (curDimensions.width > 800) {
            return 4
        }
        if (curDimensions.width > 500) {
            return 3
        }
        return 3
    }

    const getNumArrowsHorizontal = (curDimensions: Dimensions): number => {
        if (curDimensions.width > 800) {
            return 7
        }
        if (curDimensions.width > 600) {
            return 6
        }
        if (curDimensions.width > 500) {
            return 5
        }
        if (curDimensions.width > 400) {
            return 4
        }
        return 3
    }

    /**
     * This function checks whether an element should be shown in the current step.
     * It checks kind of if the passed step is <= curStep
     *
     * @param step the step of the element to check.
     */
    const showInCurStep = (step: TwitterPipelineSteps): boolean => {
        switch (curStep) {
            case "result":
                return true;
            case "logic":
                return step !== 'result'
            case "ChatGPT":
                return step === 'ChatGPT'
        }
    }

    const getClassModifier = (step: TwitterPipelineSteps): string => {
        return showInCurStep(step) ? 'show' : 'hidden'
    }

    return (
        <div {...props} className={'TwitterPipeline ' + props.className ?? ''}>
            <ViewFactDescription factDescription={factDescription} textOverwrites={viewFactDescriptionTextOverwrites}/>
            <div className={'pipeline'}>
                <Arrows moving={curStep === 'ChatGPT' && isLoading}
                        direction={'down'}
                        numArrows={getNumArrowsVertical(windowDimensions)}
                        className={'tweet-to-ChatGPT ' + getClassModifier('ChatGPT')}
                />
                <BotIcon isLoading={curStep === 'ChatGPT' && isLoading}
                         className={'ChatGPT ' + getClassModifier('ChatGPT')}
                         aria-label={'Roboter Icon mit Namen ChatGPT'}
                />
                <div className={'ChatGPT-label label ' + getClassModifier('ChatGPT')}>
                    <strong>ChatGPT</strong>
                </div>

                <Arrows moving={curStep === 'logic' && isLoading}
                        direction={'right'}
                        numArrows={getNumArrowsHorizontal(windowDimensions)}
                        className={'ChatGPT-to-logic ' + getClassModifier('logic')}
                />
                <FaBook className={'Logic ' + getClassModifier('logic')}
                        aria-label={'Buch'}/>
                <div className={'Logic-label label ' + getClassModifier('logic')}>
                    <strong>Wissens&shy;datenbank</strong>
                </div>

                <Arrows moving={curStep === 'result' && isLoading}
                        direction={'right'}
                        numArrows={getNumArrowsHorizontal(windowDimensions)}
                        className={'logic-to-result ' + getClassModifier('result')}
                />
                <FaCircleCheck className={'Result ' + getClassModifier('result')}
                               aria-label={'Haken'}/>
                <div className={'Result-label label ' + getClassModifier('result')}>
                    <strong>Ergebnis</strong>
                </div>
            </div>
        </div>
    )
}

export default TwitterPipeline;