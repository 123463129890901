import "./LoadingTweet.scss"
import React, {HTMLAttributes} from 'react';
import {FaAngleLeft, FaAngleRight, FaComputer, FaGlobe} from "react-icons/fa6";

interface LoadTweetProps extends HTMLAttributes<HTMLDivElement> {

}

function LoadingTweet({...props}: LoadTweetProps) {
    return (
        <div {...props} className={'LoadingTweet'}>
            <FaComputer className={'computer-icon'}/>
            <div className={'connection-animation-container'}>
                <div className={'animation-line'}>
                    <div className={'arrows right'}><FaAngleRight/> <FaAngleRight/> <FaAngleRight/> <FaAngleRight/></div>
                </div>
                <div className={'animation-line'}>
                    <div className={'arrows left'}><FaAngleLeft/> <FaAngleLeft/> <FaAngleLeft/> <FaAngleLeft/></div>
                </div>
            </div>
            <FaGlobe className={'internet-icon'}/>
            <div className={'load-tweet-text'}>Tweet wird geladen</div>
            <div className={'twitter-name'}>X.com</div>
        </div>
    )
}

export default LoadingTweet;