import "./MultiModalInput.scss"
import React, {HTMLAttributes, useEffect, useState} from 'react';
import {InputModality} from "../../../types/types";
import {useExpertMode} from "../../../hooks/settingsCTXHooks/useExpertMode";
import FetchTweet from "../modalities/FetchTweet/FetchTweet";
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import TextualDescriptionInput from "../modalities/TextualDescription/TextualDescriptionInput";

const MODALITIES_NORMAL_MODE: InputModality[] = ["text"]
const MODALITIES_EXPERT_MODE: InputModality[] = ["text"]

interface MultiModalInputProps extends HTMLAttributes<HTMLDivElement> {

}

function MultiModalInput({...props}: MultiModalInputProps) {
    const [expertMode] = useExpertMode();
    const modalities = expertMode ? MODALITIES_EXPERT_MODE : MODALITIES_NORMAL_MODE
    const [selectedModality, setSelectedModality] = useState<InputModality>(modalities[0])
    const className = 'MultiModalInput ' + (props.className ?? '')

    useEffect(() => {
        setSelectedModality(modalities[0])
    }, [expertMode, modalities])

    return (
        <div {...props} className={className}>
            {
                modalities.length > 1 &&
                <ToggleButtonGroup type={'radio'}
                                   name={'input-modality'}
                                   value={selectedModality}
                                   onChange={setSelectedModality}
                                   className={'MultiModalInput-switch'}
                >
                    {modalities.map(modality =>
                        <ToggleButton key={modality}
                                      value={modality}
                                      type={'radio'}
                                      id={`input-modality-${modality}`}
                                      variant={selectedModality === modality ? 'secondary' : 'outline-secondary'}>
                            {modalityToText(modality)}
                        </ToggleButton>)}
                </ToggleButtonGroup>
            }
            <div>
                {modalityToComponent(selectedModality)}
            </div>
        </div>
    )
}

function modalityToComponent(modality: InputModality) {
    switch (modality) {
        case "tweet":
            return <FetchTweet/>
        case "text":
            return <TextualDescriptionInput/>
    }
}

function modalityToText(modality: InputModality): string {
    switch (modality) {
        case "tweet":
            return "Tweet"
        case "text":
            return "Freitext"
    }
}

export default MultiModalInput;