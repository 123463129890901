import "./ProcessingTimeIndicator.scss"
import React, {HTMLAttributes, ReactElement, useEffect, useState} from 'react';
import {FaHourglassEnd, FaHourglassHalf, FaHourglassStart} from "react-icons/fa6";
import {IconBaseProps} from "react-icons";

// time in ms after which a single animation step should be made
const ANIMATION_STEP_TIME = 500;

/**
 * This function takes a time difference in ms and formulates the remaining time as a nice text.
 * Something like: "1 minute", "less than 30s"
 * @param remainingTime
 */
function remainingTimeToText(remainingTime: number): string {
    const ONE_SEC = 1000
    const ONE_MIN = 60 * ONE_SEC
    const ONE_HOUR = 60 * ONE_MIN
    const ONE_DAY = 24 * ONE_HOUR

    if (remainingTime > ONE_DAY) {
        return "mehr als ein Tag"
    }
    if (remainingTime > ONE_HOUR) {
        return "mehr als eine Stunde"
    }
    if (remainingTime >= 10 * ONE_MIN) {
        const missingMinutes = Math.floor(remainingTime / (10 * ONE_MIN)) * 10
        return `mehr als ${missingMinutes} Minuten`
    }
    if (remainingTime > 2 * ONE_MIN) {
        return "wenige Minuten"
    }
    if (remainingTime > 1.5 * ONE_MIN) {
        return "mehr als eine Minute"
    }
    if (remainingTime > 55 * ONE_SEC) {
        return "ca. eine Minute"
    }
    if (remainingTime > 45 * ONE_SEC) {
        return "weniger als eine Minute"
    }
    if (remainingTime > 35 * ONE_SEC) {
        return "ca. 40 Sekunden"
    }
    if (remainingTime > 25 * ONE_SEC) {
        return "ca. 30 Sekunden"
    }
    if (remainingTime > 18 * ONE_SEC) {
        return "ca. 20 Sekunden"
    }
    if (remainingTime > 8 * ONE_SEC) {
        return "ca. 15 Sekunden"
    }
    if (remainingTime > ONE_SEC) {
        return "ca. 10 Sekunden"
    }
    return "wenige Sekunden"
}

interface ExpectedProcessingTimeIndicatorProps extends HTMLAttributes<HTMLDivElement> {
    expectedTime: number,
    message?: string | ReactElement
}

/**
 * This component indicates that a process is currently running.
 * It indicates the expected missing processing time.
 * You must pass an expected time for this process.
 * The component then solely relies on this expected value to calculate the time left that is shown to the user.
 *
 * NOTE: if the process finished, you have to remove the component yourself from its parent.
 *
 * @param expectedTime the expected time of the tracked process
 * @param message an arbitrary message that is shown before the time estimation
 * @param props
 * @constructor
 */
function ProcessingTimeIndicator({expectedTime, message, ...props}: ExpectedProcessingTimeIndicatorProps) {
    // the elapsed time since creation of this component in ms
    const [elapsedTime, setElapsedTime] = useState<number>(0)
    const [reachedExpectedTime, setReachedExpectedTime] = useState<boolean>(false)
    const remainingTime = reachedExpectedTime? 0 : expectedTime - elapsedTime

    useEffect(() => {
        if (elapsedTime > expectedTime) {
            setReachedExpectedTime(true)

            // also reduce elapsed time, so we do not count infinitely
            // NOTE: for smoother animations, we only want to reduce the time after at least one full animation step
            if (elapsedTime >= 4 * ANIMATION_STEP_TIME) {
                // then we reduce the elapsed time by a factor of the animation time to guarantee smooth animations
                setElapsedTime(elapsedTime % ANIMATION_STEP_TIME)
            }
        }

        const timeout = setTimeout(() => setElapsedTime(elapsedTime + ANIMATION_STEP_TIME), ANIMATION_STEP_TIME)
        return () => clearTimeout(timeout)
    }, [elapsedTime, expectedTime])

    return (
        <div {...props} className={'ProcessingTimeIndicator ' + props.className ?? ''}>
            <Hourglass elapsedTime={elapsedTime} animationStepTime={ANIMATION_STEP_TIME}/>
            <div>
                {message ?? ''}
                <div>
                    Erwartete verbleibende Zeit:&nbsp;
                    <strong>
                        {remainingTimeToText(remainingTime)}
                    </strong>.
                </div>
            </div>
        </div>
    )
}

interface HourglassProps extends IconBaseProps {
    elapsedTime: number,
    animationStepTime: number
}

function Hourglass({elapsedTime, animationStepTime, ...props}: HourglassProps) {
    // this indicates the number of the step we are currently in
    const animationStep = Math.floor(elapsedTime / animationStepTime)

    // now we decide which of the hourglasses to show according to the animation step
    switch (animationStep % 4) {
        case 0:
            return <FaHourglassStart {...props} className={'Hourglass ' + props.className ?? ''}/>
        case 1:
            return <FaHourglassHalf {...props} className={'Hourglass ' + props.className ?? ''}/>
        case 2:
            return <FaHourglassEnd {...props} className={'Hourglass ' + props.className ?? ''}/>
        case 3:
            return <FaHourglassEnd {...props} className={'Hourglass rotate ' + props.className ?? ''}/>
    }
    return <FaHourglassStart {...props}/>
}

export default ProcessingTimeIndicator;