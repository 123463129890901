import "./ViewNetworkError.scss"
import React, {HTMLAttributes, useEffect, useState} from 'react';
import {AxiosError} from "axios";
import {FaRegCircleXmark} from "react-icons/fa6";

const TOO_MANY_REQUESTS_CODE = 429

interface ViewNetworkErrorProps extends HTMLAttributes<HTMLDivElement> {
    axiosError: AxiosError,
    errorMessageOverwrite?: string | ((error: AxiosError) => string) | undefined
}

function ViewNetworkError({axiosError, errorMessageOverwrite, ...props}: ViewNetworkErrorProps) {
    const [errorMessage, setErrorMessage] = useState<string>()
    const [errorCode, setErrorCode] = useState<string | number | undefined>('')

    useEffect(() => {
        if (errorMessageOverwrite === undefined) {
            setErrorMessage(axiosError.response?.statusText)
            return
        }
        if (typeof errorMessageOverwrite === 'string') {
            setErrorMessage(errorMessageOverwrite)
            return;
        }
        setErrorMessage(errorMessageOverwrite(axiosError))
    }, [errorMessageOverwrite, axiosError])

    useEffect(() => {
        const code = axiosError.response? axiosError.response.status : axiosError.code
        setErrorCode(code)
    }, [axiosError])

    useEffect(() => {
        if (errorCode === TOO_MANY_REQUESTS_CODE) {
            setErrorMessage('Zu viele Anfragen in zu kurzer Zeit. Bitte versuchen Sie es in 45 Sekunden erneut.')
        }
    }, [errorCode])

    return (
        <div {...props} className={'ViewNetworkError'}>
            <FaRegCircleXmark aria-label={'Fehler-Icon'}
                           className={'error-icon'}
            />
            <div className={'error-message'}>
                <strong>{errorMessage}</strong> [Code: {errorCode}]
            </div>
        </div>
    )
}

export default ViewNetworkError;