import {ChatGPTResponse} from "../types/swishAPI";

export const DEFAULT_CHATGPT_RESPONSE: ChatGPTResponse = {
    annunciation: {
        valueJudgement: "unknown",
        identificationWithContent: "unknown",
        untruthfulness: "unknown",
        circumstancesInsult: "unknown",
        formInsult: "unknown",
        spreading: "unknown",
        assert: "unknown",
    },
    illegality: {
        consent: "unknown",
        exerciseOfLegitimateInterests: "unknown",
        mutualInsult: "unknown",
    },
    insult185: {
        formalInsult: "unknown",
        abusiveCriticism: "unknown",
        attackOnHumanDignity: "unknown",
        context: "unknown",
        individualCaseClassification: "unknown",
        weighingUp: "unknown",
    },
    intent: "unknown",
    intentUntruthfulness: "unknown",
    libel186u187: {
        breachOfLaw: "unknown",
        breachOfContract: "unknown",
        indecency: "unknown",
        individualCaseClassification: "unknown",
        creditRisk: "unknown",
    },
    offendersAge: -1,
    recipients: [],
    statement: "",
    victim: {
        victimName: "",
        naturalPerson: "unknown",
        communityOfPersons: "unknown",
        collectiveDesignation: "unknown",
        legalPerson: "unknown",
    }
}